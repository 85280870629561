import React from "react";
import { useState, useEffect } from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Image,
  LinkBox,
  Hr,
  Icon,
  Link,
  Button,
  Section,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getAllBlogs } from "redux/Blogs/blogs-actions";

const Blogs = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.BlogDetails.Blog);
  console.log("The data", data);

  const [blogs, setBlogs] = useState(data);
  console.log("my blogs are", blogs);

  useEffect(() => {
    console.log("my useEfffect runs and dispatched the action");
    dispatch(getAllBlogs(data));
  }, []);

  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"works"} />
      <Helmet>
        <title>Works</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <div>
        {" "}
        {data.map((e) => {
          return (
            <LinkBox href="/detail Overview" className="w-full">
              <div className="w-full">
                <Box
                  margin="0px 0px 24px 0px"
                  align-items="center"
                  display="flex"
                  sm-display="block"
                  justify-content="flex-start"
                  sm-flex-direction="column"
                  sm-align-items="flex-start"
                >
                  <Box
                    margin="0px 20px 0px 0px"
                    sm-margin="0px 0px 10px 0px"
                    width="30%"
                    sm-width="100%"
                  >
                    <Image
                      src={process.env.REACT_APP_STORAGE_URL_2 + e.image}
                      border-radius="12px"
                      object-fit="cover"
                      width="275px"
                      height="215px"
                      sm-width="100%"
                      className="hoverText"
                      md-width="240px"
                      md-height="180px"
                    />
                  </Box>
                  <Box width="70%" sm-width="100%">
                    <Text
                      margin="0px 0px 16px 0px"
                      font="--headline3"
                      color="--dark"
                      md-width="100%"
                      width="100%"
                      className="hoverText"
                      lg-font="--headline3"
                      md-font="--headline4"
                      sm-font="--base1"
                    >
                      {e.title}
                    </Text>
                    <Box display="flex" margin="0px 0px 20px 0px" width="100%">
                      <Text
                        font="--m18"
                        color="--light"
                        margin="0px 26px 0px 0px"
                        padding="2px 10px 2px 10px"
                        background="--dark"
                        border-radius="16px"
                        display="flex"
                        justify-content="center"
                        align-items="center"
                        className="hoverText"
                        lg-font="--m16"
                        md-font="--m14"
                        sm-font="--b12"
                      >
                        {new Date(e.createdAt).toLocaleDateString()}
                      </Text>
                      <div className="flex gap-2 items-center">
                        {" "}
                        {e.categoryID.map((item, idx) => (
                          <Text
                            font="--r18"
                            color="--darkL2"
                            opacity="0.5"
                            margin="0px 0px 0px 0px"
                            className="hoverText"
                            lg-font="--r16"
                            md-font="--r14"
                            sm-font="--r12"
                            key={idx}
                          >
                            {item.categoryName}
                          </Text>
                        ))}
                      </div>
                    </Box>
                    <Text
                      margin="0px 0px 0px 0px"
                      font="--lead"
                      color="--darkL2"
                      md-width="100%"
                      className="hoverText  line-clamp3"
                      lg-font="--r16"
                      md-font="--r14"
                      sm-font="--r12"
                      dangerouslySetInnerHTML={{ __html: e.content }}
                    ></Text>
                  </Box>
                </Box>
              </div>
              <Hr
                min-height="1px"
                min-width="100%"
                margin="0px 0px 29px 0px"
                height="1px"
                border-style="solid"
                border-color="#cacaca"
              />
            </LinkBox>
          );
        })}
      </div>
    </Theme>
  );
};

export default Blogs;
