import apis from "../../apis/apis";
export const getBrandProfile = (payload) => {
  return async (dispatch) => {
    try {
      console.log("slice start");
      // let payload = {
      //   projectId: process.env.REACT_APP_ID,
      //   projectName: process.env.REACT_APP_COMMUNITY_NAME,
      // };
      // let payload = {
      //   projectId: "64143e473a34d024e3482036",
      //   projectName: "nhrdn",
      // };
      console.log(payload, "payload ");
      let payload1 = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      let project = await apis.user.getProject(payload1);

      let { data } = await apis.brandprofile.getBrandProfile({
        projectId: project.data.data._id,
        projectName: project.data.data.projectName,
      });

      console.log(data.data, "BD");

      if (data) dispatch(get_brandDetails(data.data[0]));
    } catch (error) {
      console.log(error);
    }
  };
};

export const get_brandDetails = (data) => {
  return {
    type: "getBrandDetails",
    payload: data,
  };
};
