import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";

import Row from "react-bootstrap/Row";
import FeatherIcon from "feather-icons-react";
import { LineWave } from "react-loader-spinner";
import Col from "react-bootstrap/Col";
import { getCms } from "redux/CMS/cms-actions";

import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { getVariations } from "redux/variations/variation-action";

import { useLocation, NavLink } from "react-router-dom";
import { getAllMenu, setLoading } from "redux/Menu/menu-actions";
import { Image } from "@quarkly/widgets";
import { useDispatch, useSelector } from "react-redux/es/exports";

// import User from "apis/user";

const NavbarHeader = (props) => {
  let { pathname } = useLocation();
  const [navbar, setnavbar] = useState(0);
  const setclick = () => setnavbar(false);
  // const [loading, setLoading] = useState((state) => state.loading);
  const dispatch = useDispatch();
  // const location = useLocation();
  // const { override, children, resdert } = useOverrides(
  //   props,
  //   overrides,
  //   defaultProps
  // );
  let loading = useSelector((state) => state.menu.loading);
  let data = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  useEffect(() => {
    console.log("Header");
    async function mount() {
      dispatch(getVariations({ pageId: "6299b8fcd3a5d92deb0b908d" }));
      // await dispatch(getProject());
      // awaiawait dispatch(setLoading(false));t dispatch(getUser({ projectId: project._id }));

      await dispatch(getAllMenu());
      await dispatch(setLoading(true));
      // await dispatch(
      //   getBrandProfile({
      //     projectId: project._id,
      //     projectName: project.projectName,
      //   })
      // );

      // setLoading(false);
      await dispatch(
        getBrandProfile({
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
      );
      await dispatch(getCms());
      const timer = setTimeout(async () => {
        await dispatch(setLoading(false));
      }, 2000);

      return () => clearTimeout(timer);
      // await dispatch(getAllProduct());
    }
    mount();
  }, [dispatch]);

  console.log(data, "menu");

  // const renderButton = (props, ref) => {
  //   return (
  //     <button {...props} ref={ref} className="flex ite">
  //       <FeatherIcon icon="user" className="textblack " size="20px" />
  //       <FeatherIcon icon="chevron-down" className="textblack " size="20px" />
  //     </button>
  //   );
  // };

  function getImages(value) {
    return "https://nimbuscluster.blob.core.windows.net/server01/smlasrado/cms/smlasrado%20logo.png";
    // return value
    //   ? value.primaryLogo
    //     ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
    //     : "https://nexuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png"
    //   : "https://nexuscluster.blob.core.windows.net/server01/brainedvault/images/deflogo.png";
  }
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondary",
        brandProfile.secondaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tertiary",
        brandProfile.tertiaryColor
      );
  }
  applyThemeToDocument();
  if (window.location.pathname === "/login") return null;
  if (window.location.pathname === "/register") return null;
  if (window.location.pathname === "/forgot-password") return null;
  return (
    <div className="w-full">
      {loading ? (
        <LineWave
          height="130"
          width="130"
          color="--qtheme-color-primary"
          ariaLabel="line-wave"
          wrapperClass="loader-set"
          wrapperStyle={{}}
          visible={true}
        />
      ) : (
        <Navbar
          expand="lg"
          className="zindex fixed flex top-0 w-full bgprimary"
        >
          <div className="flex justify-between items-center container mx-auto w-full">
            <div className="lg:block md:hidden hidden">
              <Navbar.Brand href="#" className="text-white flex  items-center">
                <NavLink to={"/Home"}>
                  <Image
                    max-width="260px"
                    height="100%"
                    src={getImages(brandProfile)}
                  />{" "}
                </NavLink>
              </Navbar.Brand>
            </div>
            <div className="widthsm ">
              <div className="flex justify-between items-center w-full px-2">
                <div className="">
                  <Navbar.Brand href="#" className="lg:hidden block text-white">
                    <NavLink to={"/Home"}>
                      <Image width="150px" src={getImages(brandProfile)} />
                    </NavLink>
                  </Navbar.Brand>
                </div>
                <div>
                  <Navbar.Toggle
                    aria-controls="navbarScroll"
                    className="w-full"
                  />
                </div>
              </div>
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto flex items-center nav-pad"
                  style={{ maxHeight: "200px" }}
                  navbarScroll
                >
                  {data.map((e, i) => {
                    return (
                      <div className="flex">
                        {e.pageData.length === 1 ? (
                          <div className="lg:px-6 flex py-2 ">
                            <NavLink
                              exact
                              to={`/${e.pageData[0].page}`}
                              onClick={() => setnavbar(i)}
                              className={
                                navbar == i
                                  ? "themefamily fsize16 font-semibold textsecondary"
                                  : "themefamily fsize16 font-normal text-white"
                              }
                            >
                              {e.menuName}
                            </NavLink>
                          </div>
                        ) : (
                          e.pageData.length > 1 && (
                            <p className="themefamily fsize14 font-bold  underlino-blck">
                              {/* {e.menuName} */}
                            </p>
                          )
                        )}
                        {e.pageData.length > 1 ? (
                          <div className="flex py-0 new-drop ">
                            <NavDropdown
                              id="nav-dropdown-dark-example"
                              className="flex themefamily fsize14 font-semibold  underlino-blck items-center"
                              menuVariant="black"
                              title={e.menuName}
                            >
                              {e.pageData.map((i) => {
                                return (
                                  <NavDropdown.Item
                                    href="#"
                                    className="themefamily fsize14 font-semibold flex  underlino-blck"
                                  >
                                    <NavLink
                                      exact
                                      to={`/${e.pageData[0].page}`}
                                      className="themefamily fsize16 font-semibold  underlino-blck"
                                    >
                                      {i.page}
                                    </NavLink>
                                  </NavDropdown.Item>
                                );
                              })}
                            </NavDropdown>
                          </div>
                        ) : (
                          ""
                        )}

                        {e.pageData.length === 0 ? (
                          <div className="mega-menu">
                            <NavDropdown
                              className="themefamily fsize16 text-black lg:ml-1 font-semibold flex underlino-blck "
                              title={e.menuName}
                              id="basic-nav-dropdown"
                            >
                              <div className="eventsNav ">
                                <Row>
                                  <Col
                                    xs="12"
                                    md="12"
                                    className="text-left grid grid-cols-4 items-start gap-4 rounded-cancel"
                                  >
                                    {e.subMenu.map((y) => {
                                      return (
                                        <div className="pb-2 borderb">
                                          <Dropdown.Header className="fsize16 py-0 text-danger font-semibold">
                                            {y.menuName}
                                          </Dropdown.Header>
                                          {y.pageData.map((data) => {
                                            return (
                                              <Dropdown.Item className="fsize12 py-1 text-gray font-normal ">
                                                <NavLink
                                                  onClick={setclick}
                                                  exact
                                                  to={`/${y.pageData[0].page}`}
                                                  className="themefamily fsize16 font-semibold  underlino-blck"
                                                >
                                                  {data.name}
                                                </NavLink>{" "}
                                              </Dropdown.Item>
                                            );
                                          })}
                                        </div>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </div>
                            </NavDropdown>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </Nav>
                {/* <SocialMedia
                pinterest="https://www.pinterest.com/"
                facebook="https://www.facebook.com"
                instagram="https://instagram.com/"
                linked-in="https://www.linkedin.com/in/"
              >
                <Override
                  slot="link"
                  border-radius="50%"
                  color="--grey"
                  hover-color="--light"
                  background="transparent"
                  hover-background="transparent"
                  margin="0 5px 0 5px"
                  padding="0px 0px 0px 0px"
                />
                <Override slot="icon-facebook" color="#000000" />
                <Override
                  slot="icon"
                  color="--darkL1"
                  padding="10px 10px 10px 10px"
                  border-color="--color-grey"
                  border-style="solid"
                  size="40px"
                  border-radius="50px"
                  background="--color-lightD2"
                  border-width="0px"
                  lg-width="30px"
                  lg-height="30px"
                />
              </SocialMedia> */}
                <div>
                  <a
                    href="mailto:stanlasrado@gmail.com"
                    className="opacity-80 text-white flex items-center"
                  >
                    <FeatherIcon icon="mail" />
                    <span className="pl-2">stanlasrado@gmail.com</span>
                  </a>
                  <a
                    href="tel:+91 2235729814"
                    className="pt-2 opacity-80 text-white flex items-center"
                  >
                    <FeatherIcon icon="phone" />
                    <span className="pl-2">+91 2235729814</span>
                  </a>
                </div>
              </Navbar.Collapse>
            </div>
          </div>
        </Navbar>
      )}
    </div>
  );
};

export default NavbarHeader;
