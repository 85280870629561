import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Contact Us"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    dispatch(getBrandProfile());
    console.log("Home");
    setMounted(true);
    dispatch(
      getBrandProfile({
        projectId: project._id,
        projectName: project.projectName,
      })
    );
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");
  console.log(getBrandProfile, "checking");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return "https://nexuscluster.blob.core.windows.net/server01/undefined/0.841612049515601.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contact"} />
      <Helmet>
        <title>Contact</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        margin="0px 0px 0px 0px"
        padding="80px 0 80px 0"
        background="linear-gradient(0deg,rgba(0, 0, 0, 0.28) 0%,rgba(0, 0, 0, 0.43) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0%/cover repeat scroll padding-box"
        quarkly-title="Contact"
        align-items="center"
        justify-content="center"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
        sm-height="430px"
        sm-background="linear-gradient(0deg,rgba(0, 0, 0, 0.2) 0%,rgba(0, 0, 0, 0.5) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1542435503-956c469947f6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) bottom/cover repeat scroll padding-box"
        id="9658082663"
      >
        <Override
          slot="SectionContent"
          sm-align-items="center"
          sm-justify-content="center"
        />
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          padding="0px 200px 0px 200px"
          width="100%"
          lg-padding="0px 100px 0px 100px"
          md-padding="0px 50px 0px 50px"
          sm-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 30px 0px"
            font="--headline1"
            color="--light"
            text-align="center"
            sm-font="--headline4"
            className="hoverText"
            lg-font="--headline2"
            md-font="--headline3"
            sm-margin="0px 0px 24px 0px"
            id="0023332330"
            onClick={() => isSideBarOpen("0023332330")}
          >
            {data ? data["0023332330"] : "Contacts"}
          </Text>
          <Text
            margin="0px 0px 48px 0px"
            font="--base"
            color="--light"
            text-align="center"
            className="hoverText"
            lg-font="--r16"
            md-font="--r14"
            sm-font="--r12"
            sm-margin="0px 0px 30px 0px"
            id="3487860206"
            onClick={() => isSideBarOpen("3487860206")}
          >
            {data
              ? data["3487860206"]
              : "Curabitur lobortis id lorem id bibendum. Ut id consectetur magna  Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at. Vestibulum nec erat ut mi sollicitudin porttitor id sit amet risus. Nam tempus vel odio vitae aliquam."}
          </Text>
          <Link
            href="mailto:blank?hello@company.com"
            text-align="center"
            color="--light"
            font="--base1"
            text-decoration-line="initial"
            margin="0px 0px 16px 0px"
            className="hoverText"
            lg-font="--m18"
            md-font="--m14"
            id="5936791880"
            onClick={() => isSideBarOpen("5936791880")}
          >
            {data ? data["5936791880"] : "hello@company.com"}
          </Link>
          <Link
            href="tel:1234567899"
            text-align="center"
            color="--light"
            font="--base1"
            text-decoration-line="initial"
            margin="0px 0px 16px 0px"
            className="hoverText"
            lg-font="--m18"
            md-font="--m14"
            id="1797695486"
            onClick={() => isSideBarOpen("1797695486")}
          >
            {data ? data["1797695486"] : "(123) 456-78-99"}
          </Link>
          <Text
            margin="0px 0px 48px 0px"
            font="--base1"
            color="--light"
            text-align="center"
            className="hoverText"
            lg-font="--m18"
            md-font="--m14"
            sm-margin="0px 0px 0px 0px"
            id="3023381946"
            onClick={() => isSideBarOpen("3023381946")}
          >
            {data ? data["3023381946"] : "4998 Hanover Street, New York, 10011"}
          </Text>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64377e87d28fe100213859da"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
