import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import QAPI from "qapi";
import { i18n } from "element-react";
import locale from "element-react/src/locale/lang/en";
import "element-theme-default";
import "../src/assets/styles/tailwind.css";
import "../src/assets/styles/tailwind.generated.css";
import "./assets/scss/Font.scss";
import "./assets/scss/Index.scss";
import "./assets/css/index.css";

i18n.use(locale);
window.QAPI = QAPI;
ReactDOM.render(<App />, document.getElementById("root"));
