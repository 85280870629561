import React, { useEffect } from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { NavLink, useParams } from "react-router-dom";
import { getAllMenu } from "redux/Menu/menu-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";

import ContentUpdate from "components/CMS/ContentUpdate";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";

export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  let project = useSelector((state) => state.userDetails.project);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let data = useSelector((state) => state.cms.pages["About Us"]);
  const [selectedData, updateSelectedData] = useState("update");
  let menuData = useSelector((state) => state.menu.menu);
  useEffect(() => {
    dispatch(getCms());
    dispatch(getAllMenu());
    dispatch(
      getBrandProfile({
        projectId: project._id,
        projectName: project.projectName,
      })
    );
    setMounted(true);
  }, [dispatch, setMounted]);
  console.log(data, "outside");

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
    dispatch(setCmsActive(true));
  } else {
    dispatch(setCmsActive(false));
  }

  //----------------hide/show-----------

  function getFavicon(value) {
    return "https://nexuscluster.blob.core.windows.net/server01/undefined/0.841612049515601.png";
  }
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"About Us"} />
      <Helmet>
        <title>About Us</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        text-align="center"
        padding="80px 0"
        sm-padding="40px 0"
        tablat-padding="40px 0 40px 0"
        quarkly-title="Hero Section"
      >
        <Text
          as="h1"
          font="--headline1"
          md-font="--headline2"
          margin="20px 0 0 0"
          id="4437985773"
          className="hoverText themefamily"
          onClick={() => isSideBarOpen("4437985773")}
        >
          {data ? data["4437985773"] : "About Us"}
        </Text>
        <Text
          as="p"
          font="--paragraph"
          margin="20px 0 0 0"
          className="hoverText themefamily"
          id="8580750456"
          onClick={() => isSideBarOpen("8580750456")}
        >
          {data
            ? data["8580750456"]
            : "Hi! I'm a paragraph. Click here to add your own text and edit me.  Itâ€™s a piece of cake. Iâ€™m a great space for you to tell a story and let your site visitors know more about you. Talk about your    business and what products and services you offer. Share how you came  up with the idea for your company and what makes you different from  your competitors. Make your business stand out and show your visitors  who you are."}
        </Text>
        <Box
          margin="40px 0 20px 0"
          justify-content="space-around"
          sm-flex-direction="column"
          className="lg:grid md:grid grid-cols-3 lg:gap-20 md:gap-20 gap-8"
        >
          <Box
            id="9524864922"
            width="100%"
            onClick={() => isSideBarOpen("9524864922")}
            className="hoverText sm-mb2rem"
          >
            <Image
              src={
                data
                  ? data["9524864922"]
                  : "https://images.unsplash.com/photo-1501870190084-cdf29f15ef87?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80"
              }
              width="100%"
              max-width="100%"
              height="420px"
              object-fit="cover"
              background="#ececec"
            />
          </Box>
          <Box
            className="hoverText sm-mb2rem"
            width="100%"
            id="3240006645"
            onClick={() => isSideBarOpen("3240006645")}
          >
            <Image
              src={
                data
                  ? data["3240006645"]
                  : "https://images.unsplash.com/photo-1503341960582-b45751874cf0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=60"
              }
              width="100%"
              max-width="100%"
              height="420px"
              object-fit="cover"
              background="#ececec"
            />
          </Box>
          <Box
            className="hoverText sm-mb2rem"
            id="9751100315"
            width="100%"
            onClick={() => isSideBarOpen("9751100315")}
          >
            <Image
              src={
                data
                  ? data["9751100315"]
                  : "https://images.unsplash.com/photo-1503342394128-c104d54dba01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=600&q=80"
              }
              width="100%"
              max-width="100%"
              height="420px"
              object-fit="cover"
              background="#ececec"
            />
          </Box>
        </Box>
      </Section>
      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"63e20e3f3a0508001e073e4c"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
