// import axios from "axios";
import apis from "../../apis/blogs";
export const getAllBlogs = (payload) => {
  return async (dispatch) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getAllBlogs(payload);

      console.log(data.data.blogs, "project");
      if (data) dispatch(setBlog(data.data.blogs));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllCategory = (payload) => {
  return async (dispatch) => {
    try {
      let payload = {
        projectName: process.env.REACT_APP_COMMUNITY_NAME,
      };
      //   let { data } = await apis.user.getProject(payload);
      let { data } = await apis.getAllCategory(payload);

      console.log(data.data, "project1");
      if (data) dispatch(setCategory(data.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const setBlog = (data) => {
  return {
    type: "setBlog",
    payload: data,
  };
};

export const setCategory = (data) => {
  return {
    type: "setCategory",
    payload: data,
  };
};

export const addSingleBlog = (data) => {
  console.log(data, "omkar");
  return {
    type: "ADD_SINGLE_BLOG",
    payload: data,
  };
};
