export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"63e20e3f3a0508001e073e53",
				"63e20e3f3a0508001e073e56",
				"64366b61b4ef24002072c31d",
				"642c0389aed6310020bee248",
				"642d187faed6310020beeb0e",
				"6433b4ecb4ef24002072a65e",
				"6433b7c83dedc4001fca8ac6",
				"6405dd5eb4ef2400207065b2"
			]
		},
		"63e20e3f3a0508001e073e53": {
			"id": "63e20e3f3a0508001e073e53",
			"name": "404",
			"pageUrl": "404"
		},
		"63e20e3f3a0508001e073e56": {
			"id": "63e20e3f3a0508001e073e56",
			"name": "index",
			"pageUrl": "index"
		},
		"6405dd5eb4ef2400207065b2": {
			"id": "6405dd5eb4ef2400207065b2",
			"pageUrl": "button",
			"name": "Button"
		},
		"642c0389aed6310020bee248": {
			"id": "642c0389aed6310020bee248",
			"pageUrl": "work",
			"name": "Work"
		},
		"642d187faed6310020beeb0e": {
			"id": "642d187faed6310020beeb0e",
			"pageUrl": "detail",
			"name": "Work Details"
		},
		"6433b4ecb4ef24002072a65e": {
			"pageUrl": "detail1",
			"id": "6433b4ecb4ef24002072a65e",
			"name": "Blog Categories"
		},
		"6433b7c83dedc4001fca8ac6": {
			"pageUrl": "detail12",
			"id": "6433b7c83dedc4001fca8ac6",
			"name": "Blog Overview"
		},
		"64366b61b4ef24002072c31d": {
			"id": "64366b61b4ef24002072c31d",
			"pageUrl": "about",
			"name": "About"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}