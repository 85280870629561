import React, { useEffect, useState } from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Icon,
  Image,
  Section,
  Hr,
  LinkBox,
  Link,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import { getCms, getElementById } from "redux/CMS/cms-actions";
import { getProject, getUser } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { getAllProduct } from "redux/Product/product-action";
import { useLocation, NavLink } from "react-router-dom";
import { getAllMenu } from "redux/Menu/menu-actions";
import { useDispatch, useSelector } from "react-redux/es/exports";

export default () => {
  let { pathname } = useLocation();
  const [navabar, setnavbar] = useState(true);
  const setclick = () => setnavbar(false);

  const dispatch = useDispatch();
  // const location = useLocation();
  // const { override, children, rest } = useOverrides(
  //   props,
  //   overrides,
  //   defaultProps
  // );

  let data = useSelector((state) => state.menu.menu);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let project = useSelector((state) => state.userDetails.project);
  useEffect(() => {
    console.log("Header");
    async function mount() {
      await dispatch(getProject());
      // await dispatch(getUser({ projectId: project._id }));
      await dispatch(getAllMenu());
      await dispatch(
        getBrandProfile({
          projectName: process.env.REACT_APP_COMMUNITY_NAME,
        })
      );
      await dispatch(getCms());
      await dispatch(getAllProduct());
    }
    mount();
  }, [dispatch]);

  console.log(data, "menu");

  // const renderButton = (props, ref) => {
  //   return (
  //     <button {...props} ref={ref} className="flex ite">
  //       <FeatherIcon icon="user" className="textblack " size="20px" />
  //       <FeatherIcon icon="chevron-down" className="textblack " size="20px" />
  //     </button>
  //   );
  // };

  function getImages(value) {
    return "https://nimbuscluster.blob.core.windows.net/server01/smlasrado/cms/smlasrado%20logo.png";
    // return value
    //   ? value.primaryLogo
    //     ? process.env.REACT_APP_STORAGE_URL_2 + brandProfile.primaryLogo
    //     : "https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png"
    //   : "https://nexuscluster.blob.core.windows.net/server01/Quarkly/default/logo.png";
  }
  function applyThemeToDocument() {
    if (brandProfile && brandProfile.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        brandProfile.primaryColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("secondaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-secondaryColor",
        brandProfile.secondayColor
      );

    if (brandProfile && brandProfile.hasOwnProperty("tertiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-teritary",
        brandProfile.tertiaryColor
      );
  }
  function getFavicon(value) {
    return "https://nexuscluster.blob.core.windows.net/server01/undefined/0.841612049515601.png";
  }
  applyThemeToDocument();
  if (window.location.pathname === "/login") return null;
  if (window.location.pathname === "/register") return null;
  if (window.location.pathname === "/forgot-password") return null;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>SM LASRADO & CO</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        background="--color-primary"
        padding="10px 0 0px 0"
        sm-padding="10px 0 10px 0"
        align-items="center"
        justify-content="center"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          color="--darkL1"
        />
        <Box
          empty-border-color="LightGray"
          justify-content="space-between"
          display="flex"
          sm-flex-direction="column"
          width="100%"
          align-items="center"
        >
          <div className="py-4">
            <Image
              max-width="250px"
              margin="0px 0px 0px 0px"
              src={getImages(brandProfile)}
            />
          </div>
          <div>
            {/* <SocialMedia
              facebook="https://www.facebook.com/quarklyapp/"
              twitter="https://twitter.com/quarklyapp"
              telegram="https://t.me/joinchat/DqSYDhS0R9nMRvOtFbIxrQ"
              instagram="https://www.instagram.com/instagram/"
              align-items="flex-start"
              justify-content="flex-start"
            >
              <Override
                slot="link"
                margin="0 12px 0 0px"
                hover-background="--color-indigo"
                background="--color-greyD1"
                lg-height="28px"
                lg-padding="4px 4px 4px 4px"
                lg-display="flex"
                lg-align-items="center"
                border-radius="50%"
                lg-justify-content="center"
                lg-width="28px"
                color="--light"
                width="40px"
                height="40px"
              >
                <div display="none !important" />
              </Override>
              <Override slot="icon" lg-font="18px sans-serif" />
              <Override slot="link-telegram">
                <div display="none !important" />
              </Override>
            </SocialMedia> */}
            <p className="text-white">@2023, All rights reserved</p>
          </div>
        </Box>
      </Section>

      <RawHtml>
        <style place={"endOfHead"} rawKey={"64523a64bf72b300207ea405"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
