import React, { useEffect } from "react";
import theme from "theme";
import { createContact } from "redux/marcom/marcom-action";
import {
  Theme,
  Text,
  Button,
  Box,
  Image,
  Section,
  Hr,
  Icon,
  LinkBox,
  Input,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAllOut } from "react-icons/md";
import { TiSocialLinkedin } from "react-icons/ti";
import { FiInstagram, FiTwitter } from "react-icons/fi";
import { AiOutlineBehance } from "react-icons/ai";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Home"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlephoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleemailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlemessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let payload = {
      name: name,
      mobile: phone,
      email: email,
      description: message,
    };
    if (
      payload.name !== "" ||
      payload.mobile !== "" ||
      payload.email !== "" ||
      payload.description !== ""
    ) {
      let data = await dispatch(createContact(payload));
      console.log(data, "DATA");

      if (data.code === 200) {
        Swal.fire({
          title: "Congratulations",
          text: "Your query been successfully send.",
          icon: "success",
        });

        console.log(data.code, "registercode");
      } else {
        // alert(data.message);
        Swal.fire({
          title: "Errow",
          text: "Please enter valid credential",
          icon: "error",
        });
        console.log(data.message, "datamessage");
      }
    }
  };

  useEffect(() => {
    dispatch(getBrandProfile());
    console.log("Home");
    setMounted(true);
    dispatch(
      getBrandProfile({
        projectId: project._id,
        projectName: project.projectName,
      })
    );
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");
  console.log(getBrandProfile, "checking");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return "https://nexuscluster.blob.core.windows.net/server01/undefined/0.841612049515601.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>SM LASRADO & CO</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={getFavicon(brandProfile)}
          type={"image/x-icon"}
        />
      </Helmet>
      <Section
        lg-margin="5rem 0 0 0"
        md-margin="0rem 0 0 0"
        sm-margin="3rem 0 0 0"
        padding="0px 0 80px 0"
        lg-padding="80px 0 60px 0"
        align-items="center"
        justify-content="center"
        md-padding="20px 0 50px 0"
        sm-padding="00px 0 0px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          align-items="center"
          lg-margin="5px auto 0px auto"
          md-margin="5px auto 0px auto"
          sm-align-items="center"
          sm-flex-direction="column"
          sm-justify-content="center"
          className="mob-flexdirection  lg:pt-20"
        />
        <Box
          width="65%"
          md-width="100%"
          sm-width="100%"
          lg-margin="18px 60px 0px 0px"
          md-margin="0px 0px 0px 0px"
          sm-display="flex"
          sm-flex-direction="column"
          sm-align-items="center"
          sm-justify-content="center"
          sm-margin="0px 0px 24px 0px"
          className="lg:pr-12 lg:pt-0 md:pt-6 pt-8"
        >
          <Text
            margin="0px 0px 30px 0px"
            color="--dark"
            font="--headline1"
            width="100%"
            className="hoverText"
            lg-font="--headline2"
            lg-margin="0px 0px 32px 0px"
            sm-font="--headline4"
            md-font="--headline3"
            md-margin="0px 0px 28px 0px"
            sm-margin="0px 0px 12px 0px"
            sm-text-align="center"
            id="8444395748"
            onClick={() => isSideBarOpen("8444395748")}
          >
            {data ? data["8444395748"] : "Hi, I am John, Creative Technologist"}
          </Text>
          <Text
            margin="0px 0px 38px 0px"
            font="--base"
            color="--dark"
            width="100%"
            className="hoverText "
            lg-margin="0px 0px 30px 0px"
            md-font="--r16"
            sm-font="--r14"
            sm-text-align="center"
            id="4047444343"
            onClick={() => isSideBarOpen("4047444343")}
          >
            {/* {data
              ? data["4047444343"]
              : "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet."} */}
            Established in 1977 in Mumbai and provides a wide range of services
            that encompass accounting, taxation (includes Real Estate
            Transactions), auditing, and financial advisory. <br />
            <p className="mt-2">
              We are an experienced team of professionals providing well-thought
              out strategies and solutions to complex challenges in the changing
              regulatory environment.
            </p>
          </Text>
          <a href="#enquirenow">
            <Button
              font="--base"
              className="hoverText SkinButton"
              border-radius="4px"
              lg-font="--base"
              padding="20px 60px 20px 60px"
              md-height="50px"
              md-align-items="center"
              md-justify-content="center"
              md-padding="12px 60px 12px 60px"
              sm-padding="12px 40px 12px 40px"
              sm-font="--m14"
              id="0395708574"
              background="--color-primary"
              color="white"
              onClick={() => isSideBarOpen("0395708574")}
            >
              {data ? data["0395708574"] : "Enquire Now"}
            </Button>
          </a>
        </Box>
        <Box
          width="35%"
          md-width="100%"
          sm-width="100%"
          sm-display="flex"
          sm-justify-content="center"
        >
          <Box
            border-radius="100%"
            display="flex"
            justify-content="center"
            background="--light2"
            align-items="center"
            height="450px"
            width="450px"
            sm-width="250px"
            sm-height="250px"
            md-width="350px"
            md-height="350px"
            md-margin="auto"
            id="5536724280"
            onClick={() => isSideBarOpen("5536724280")}
          >
            <Image
              src={
                data
                  ? data["5536724280"]
                  : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
              }
              display="block"
              border-radius="100%"
              object-fit="cover"
              margin="10px 0px 10px 0px"
              height="400px"
              width="400px"
              object-position="0% 30%"
              className="hoverText"
              lg-width="300px"
              lg-height="300px"
              sm-width="200px"
              sm-height="200px"
            />
          </Box>
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        align-items="center"
        justify-content="center"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
        />
        <Box
          display="flex"
          grid-template-rows="repeat(1, 1fr)"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="16px"
          sm-grid-template-rows="repeat(3, 1fr)"
          sm-grid-template-columns="repeat(1, 1fr)"
          lg-display="flex"
          lg-flex-direction="column"
          lg-align-items="center"
          lg-justify-content="center"
          width="100%"
          md-height="fit-content"
          flex-direction="column"
          align-items="center"
        >
          <Box
            align-items="center"
            display="flex"
            justify-content="center"
            width="fit-content"
            border-width="0 0 1.4px 0"
            border-color="--color-lightgrey"
            border-style="solid"
            padding="0px 16px 0px 16px"
            flex-direction="column"
            margin="0px 0px 70px 0px"
            md-margin="0px 0px 30px 0px"
            lg-margin="0px 0px 24px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Text
              font="--headline2"
              text-align="center"
              className="hoverText"
              width="fit-content"
              md-font="--headline3"
              sm-font="--headline4"
              sm-margin="12px 0px 12px 0px"
              id="9704880595"
              onClick={() => isSideBarOpen("9704880595")}
            >
              {data ? data["9704880595"] : "SERVICES"}
            </Text>
            <Hr
              min-width="80%"
              margin="0px 0px 0px 0px"
              padding="0px 0px 0px 0px"
              color="--primary"
              background="--primary"
              border-color="--color-primary"
              border-width="1.4px 0 0 0"
            />
          </Box>
          <Box
            margin="15px 0px 0px 0px"
            display="grid"
            grid-gap="20px"
            grid-template-columns="repeat(3, 1fr)"
            sm-display="grid"
            sm-grid-template-rows="repeat(3, 1fr)"
            sm-grid-template-columns="repeat(1, 1fr)"
            width="100%"
            md-grid-template-rows="repeat(3, 1fr)"
            md-grid-template-columns="repeat(2, 1fr)"
            sm-margin="0px 0px 0px 0px"
            height="fit-content"
          >
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              align-items="center"
              justify-content="center"
              padding="15px 15px 15px 15px"
              height="350px"
              className="c-lightgrey"
              border-width="0.6px"
              border-style="solid"
              border-color="--color-primary"
              lg-height="291px"
              border-radius="8px"
            >
              <Image
                src={
                  data
                    ? data["5933827591"]
                    : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
                }
                color="--primary"
                height="80px"
                width="80px"
                sm-width="60px"
                sm-height="60px"
                id="5933827591"
                onClick={() => isSideBarOpen("5933827591")}
              />
              <Text
                font="--headline3"
                text-align="center"
                className="hoverText"
                md-font="--headline4"
                sm-font="--sm22"
                lg-font="--headline4"
                id="1646876206"
                onClick={() => isSideBarOpen("1646876206")}
              >
                {data ? data["1646876206"] : "Sketches"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r16"
                text-align="center"
                className="hoverText lh22px"
                width="80%"
                color="--grey"
                lg-font="--r14"
                sm-font="--r12"
                sm-width="100%"
                lg-width="90%"
                id="4093425257"
                onClick={() => isSideBarOpen("4093425257")}
              >
                - Accounts Consulting
                <br />- Providing Outsourced Accounting Services
                {/* {data
                  ? data["4093425257"]
                  : "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized"} */}
              </Text>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              align-items="center"
              justify-content="center"
              padding="15px 15px 15px 15px"
              height="350px"
              className="c-lightgrey"
              border-radius="8px"
              border-width="0.6px"
              border-style="solid"
              border-color="--color-primary"
              lg-height="291px"
            >
              <Image
                src={
                  data
                    ? data["7624390345"]
                    : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
                }
                color="--primary"
                height="80px"
                width="80px"
                sm-width="60px"
                sm-height="60px"
                id="7624390345"
                onClick={() => isSideBarOpen("7624390345")}
              />

              <Text
                font="--headline3"
                text-align="center"
                className="hoverText"
                md-font="--headline4"
                sm-font="--sm22"
                lg-font="--headline4"
                id="1467250136"
                onClick={() => isSideBarOpen("1467250136")}
              >
                {data ? data["1467250136"] : "Product Design"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r16"
                text-align="center"
                className="hoverText lh22px"
                width="80%"
                color="--grey"
                lg-font="--r14"
                sm-font="--r12"
                sm-width="100%"
                lg-width="90%"
                id="4129191325"
                onClick={() => isSideBarOpen("4129191325")}
              >
                - Development of internal controls
                <br />- Formation of Organization and Setup (Incorporation)
                <br />- Advice on Financial Business Ventures
                {/* {data
                  ? data["4129191325"]
                  : "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized"} */}
              </Text>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              align-items="center"
              justify-content="center"
              padding="15px 15px 15px 15px"
              height="350px"
              className="c-lightgrey"
              border-width="0.6px"
              border-radius="8px"
              border-style="solid"
              border-color="--color-primary"
              lg-height="291px"
            >
              <Image
                src={
                  data
                    ? data["4922068698"]
                    : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
                }
                color="--primary"
                height="80px"
                width="80px"
                sm-width="60px"
                sm-height="60px"
                id="4922068698"
                onClick={() => isSideBarOpen("4922068698")}
              />
              <Text
                font="--headline3"
                text-align="center"
                className="hoverText"
                md-font="--headline4"
                sm-font="--sm22"
                lg-font="--headline4"
                id="0612084700"
                onClick={() => isSideBarOpen("0612084700")}
              >
                {data ? data["0612084700"] : "Motion Graphics"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r16"
                text-align="center"
                className="hoverText lh22px"
                width="80%"
                color="--grey"
                lg-font="--r14"
                sm-font="--r12"
                sm-width="100%"
                lg-width="90%"
                id="4195060841"
                onClick={() => isSideBarOpen("4195060841")}
              >
                {data
                  ? data["4195060841"]
                  : "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized"}
              </Text>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              align-items="center"
              justify-content="center"
              padding="15px 15px 15px 15px"
              height="350px"
              border-radius="8px"
              className="c-lightgrey"
              border-width="0.6px"
              border-style="solid"
              border-color="--color-primary"
              lg-height="291px"
            >
              <Image
                src={
                  data
                    ? data["8060193482"]
                    : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
                }
                color="--primary"
                height="80px"
                width="80px"
                sm-width="60px"
                sm-height="60px"
                id="8060193482"
                onClick={() => isSideBarOpen("8060193482")}
              />

              <Text
                font="--headline3"
                text-align="center"
                className="hoverText"
                md-font="--headline4"
                sm-font="--sm22"
                lg-font="--headline4"
                id="2427369861"
                onClick={() => isSideBarOpen("2427369861")}
              >
                {data ? data["2427369861"] : "App Design"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r16"
                text-align="center"
                className="hoverText lh22px"
                width="80%"
                color="--grey"
                lg-font="--r14"
                sm-font="--r12"
                sm-width="100%"
                lg-width="90%"
                id="4145711669"
                onClick={() => isSideBarOpen("4145711669")}
              >
                - Vetting Documents
                <br />- Advisory on Purchase & Sale
                <br /> - Legal Drafting
                {/* {data
                  ? data["4145711669"]
                  : " On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized"} */}
              </Text>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              align-items="center"
              justify-content="center"
              padding="15px 15px 15px 15px"
              height="350px"
              border-radius="8px"
              className="c-lightgrey"
              border-width="0.6px"
              border-style="solid"
              border-color="--color-primary"
              lg-height="291px"
            >
              <Image
                src={
                  data
                    ? data["8126382431"]
                    : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
                }
                color="--primary"
                height="80px"
                width="80px"
                sm-width="60px"
                sm-height="60px"
                id="8126382431"
                onClick={() => isSideBarOpen("8126382431")}
              />
              <Text
                font="--headline3"
                text-align="center"
                className="hoverText"
                md-font="--headline4"
                sm-font="--sm22"
                lg-font="--headline4"
                id="8439961925"
                onClick={() => isSideBarOpen("8439961925")}
              >
                {data ? data["8439961925"] : "Responsive Design"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r16"
                text-align="center"
                className="hoverText lh22px"
                width="80%"
                color="--grey"
                lg-font="--r14"
                sm-font="--r12"
                sm-width="100%"
                lg-width="90%"
                id="7911751922"
                onClick={() => isSideBarOpen("7911751922")}
              >
                - Corporate & Allied Laws
                <br />- FEMA
                <br />- ROC Compliances
                {/* {data
                  ? data["7911751922"]
                  : "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized"} */}
              </Text>
            </Box>
            <Box
              width="100%"
              display="flex"
              flex-direction="column"
              align-items="center"
              justify-content="center"
              padding="15px 15px 15px 15px"
              height="350px"
              border-radius="8px"
              className="c-lightgrey"
              border-width="0.6px"
              border-style="solid"
              border-color="--color-primary"
              lg-height="291px"
            >
              <Image
                src={
                  data
                    ? data["5559517877"]
                    : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
                }
                color="--primary"
                height="80px"
                width="80px"
                sm-width="60px"
                sm-height="60px"
                id="5559517877"
                onClick={() => isSideBarOpen("5559517877")}
              />

              <Text
                font="--headline3"
                text-align="center"
                className="hoverText"
                md-font="--headline4"
                sm-font="--sm22"
                lg-font="--headline4"
                id="1698303160"
                onClick={() => isSideBarOpen("1698303160")}
              >
                {data ? data["1698303160"] : "UI/UX Design"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r16"
                text-align="center"
                className="hoverText lh22px"
                width="80%"
                color="--grey"
                lg-font="--r14"
                sm-font="--r12"
                sm-width="100%"
                lg-width="90%"
                id="4066916902"
                onClick={() => isSideBarOpen("4066916902")}
              >
                - Advice & Applicability of GST
                <br />- Registration
                <br />- Filing GST Returns
                <br />- Advisory
                <br />- Handling matters with Department
                {/* {data
                  ? data["4066916902"]
                  : "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized"} */}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      {/* <Section
        padding="80px 0 80px 0"
        align-items="center"
        justify-content="center"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
          flex-direction="column"
          sm-flex-direction="column"
          sm-align-items="center"
          md-flex-direction="column"
          padding="0px 78px 0px 78px"
          align-items="center"
          justify-content="center"
          lg-padding="0px 0px 0px 0px"
        />
        <Box
          display="flex"
          align-items="center"
          justify-content="center"
          width="fit-content"
          border-width="0 0 1.4px 0"
          border-color="--color-lightgrey"
          border-style="solid"
          padding="0px 16px 0px 16px"
          flex-direction="column"
          margin="0px 0px 70px 0px"
          md-margin="0px 0px 30px 0px"
          sm-margin="0px 0px 24px 0px"
        >
          <Text
            font="--headline2"
            text-align="center"
            className="hoverText"
            width="fit-content"
            md-font="--headline3"
            sm-margin="12px 0px 12px 0px"
            sm-font="--headline4"
            id="5019792461"
          >
            {data ? data["0395708574"] : "SKILLS"}
          </Text>
          <Hr
            min-width="80%"
            margin="0px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            color="--primary"
            background="--primary"
            border-color="--color-primary"
            border-width="1.4px 0 0 0"
          />
        </Box>
        <Box display="flex" width="100%" md-flex-direction="column">
          <Box
            width="50%"
            md-width="100%"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            lg-height="max-content"
            margin="0px 10px 0px 0px"
            sm-margin="0px 0px 24px 0px"
          >
            <Text
              font="--headline2"
              text-align="left"
              lg-width="100%"
              className="hoverText"
              md-font="--sm22"
              md-margin="16px 0px 0 0px"
              md-text-align="center"
              lg-margin="0px 0px 12px 0px"
              lg-font="--headline3"
              sm-margin="0px 0px 0px 0px"
              sm-font="--m18"
              id="1686236135"
              onClick={() => isSideBarOpen("1686236135")}
            >
              {data
                ? data["1686236135"]
                : "All the skills that I have in that field of work are mentioned."}
            </Text>
            <Text
              margin="20px 0px 40px 5px"
              font="--r16"
              text-align="left"
              width="100%"
              lg-width="100%"
              className="hoverText"
              color="--grey"
              md-margin="16px 0px 40px 5px"
              md-text-align="center"
              lg-margin="8px 0px 0 5px"
              md-font="--r14"
              sm-margin="16px 0px 0px 0px"
              sm-font="--r12"
              id="7157612938"
              onClick={() => isSideBarOpen("7157612938")}
            >
              {data
                ? data["7157612938"]
                : "Motion In summary, skills are important because they contribute to professional success, personal growth, adaptability, problem-solving, effective communication, collaboration, personal branding, networking, and continuous learning. By investing in skill development, you equip yourself with the tools necessary to excel in various aspects of life and navigate future challenges and opportunities."}
            </Text>
          </Box>
          <Box
            width="50%"
            padding="25px 25px 25px 30px"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            md-padding="0px 0px 0px 0px"
            sm-width="100%"
            md-width="100%"
            display="grid"
            grid-gap="20px"
            lg-grid-template-columns="repeat(1, 1fr)"
            grid-template-columns="repeat(1, 1fr)"
            lg-padding="0px 25px 0px 30px"
            sm-grid-gap="12px"
          >
            <Box width="500px" lg-width="100%">
              <Box
                width="100%"
                display="flex"
                justify-content="space-between"
                align-items="center"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--r16"
                  text-align="left"
                  sm-font="--r14"
                  id="0717215921"
                  onClick={() => isSideBarOpen("0717215921")}
                >
                  {data ? data["0717215921"] : "Photoshop"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--m16"
                  text-align="left"
                  color="--primary"
                  sm-font="--m14"
                >
                  50%
                </Text>
              </Box>
              <Box
                margin="10px 0px 0px 0px"
                height="4px"
                background="--color-lightD1"
                border-radius="0px 100px 100px 0px"
              >
                <Box
                  height="4px"
                  width="250px"
                  background="--primary"
                  border-radius="100px 0px 0px 100px"
                  md-width="50%"
                />
              </Box>
            </Box>
            <Box width="500px" lg-width="100%">
              <Box
                width="100%"
                display="flex"
                justify-content="space-between"
                align-items="center"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--r16"
                  text-align="left"
                  sm-font="--r14"
                  id="3238394549"
                  onClick={() => isSideBarOpen("3238394549")}
                >
                  {data ? data["3238394549"] : "Photoshop"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--m16"
                  text-align="left"
                  color="--primary"
                  sm-font="--m14"
                >
                  50%
                </Text>
              </Box>
              <Box
                margin="10px 0px 0px 0px"
                height="4px"
                background="--color-lightD1"
                border-radius="0px 100px 100px 0px"
              >
                <Box
                  height="4px"
                  width="250px"
                  background="--primary"
                  border-radius="100px 0px 0px 100px"
                  md-width="50%"
                />
              </Box>
            </Box>
            <Box width="500px" lg-width="100%">
              <Box
                width="100%"
                display="flex"
                justify-content="space-between"
                align-items="center"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--r16"
                  text-align="left"
                  sm-font="--r14"
                  id="2153168396"
                  onClick={() => isSideBarOpen("2153168396")}
                >
                  {data ? data["2153168396"] : "UX Design"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--m16"
                  text-align="left"
                  color="--primary"
                  sm-font="--m14"
                >
                  50%
                </Text>
              </Box>
              <Box
                margin="10px 0px 0px 0px"
                height="4px"
                background="--color-lightD1"
                border-radius="0px 100px 100px 0px"
              >
                <Box
                  height="4px"
                  width="250px"
                  background="--primary"
                  border-radius="100px 0px 0px 100px"
                  md-width="50%"
                />
              </Box>
            </Box>
            <Box width="500px" lg-width="100%">
              <Box
                width="100%"
                display="flex"
                justify-content="space-between"
                align-items="center"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--r16"
                  text-align="left"
                  sm-font="--r14"
                  id="1402955604"
                  onClick={() => isSideBarOpen("1402955604")}
                >
                  {data ? data["1402955604"] : "HTML / CSS"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--m16"
                  text-align="left"
                  color="--primary"
                  sm-font="--m14"
                >
                  50%
                </Text>
              </Box>
              <Box
                margin="10px 0px 0px 0px"
                height="4px"
                background="--color-lightD1"
                border-radius="0px 100px 100px 0px"
              >
                <Box
                  height="4px"
                  width="250px"
                  background="--primary"
                  border-radius="100px 0px 0px 100px"
                  md-width="50%"
                />
              </Box>
            </Box>
            <Box width="500px" lg-width="100%">
              <Box
                width="100%"
                display="flex"
                justify-content="space-between"
                align-items="center"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--r16"
                  text-align="left"
                  sm-font="--r14"
                  id="4252410839"
                  onClick={() => isSideBarOpen("4252410839")}
                >
                  {data ? data["4252410839"] : "Figma"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--m16"
                  text-align="left"
                  color="--primary"
                  sm-font="--m14"
                >
                  50%
                </Text>
              </Box>
              <Box
                margin="10px 0px 0px 0px"
                height="4px"
                background="--color-lightD1"
                border-radius="0px 100px 100px 0px"
              >
                <Box
                  height="4px"
                  width="250px"
                  background="--primary"
                  border-radius="100px 0px 0px 100px"
                  md-width="50%"
                />
              </Box>
            </Box>
            <Box width="500px" lg-width="100%">
              <Box
                width="100%"
                display="flex"
                justify-content="space-between"
                align-items="center"
              >
                <Text
                  margin="0px 0px 0px 0px"
                  font="--r16"
                  text-align="left"
                  sm-font="--r14"
                  id="6410685153"
                  onClick={() => isSideBarOpen("6410685153")}
                >
                  {data ? data["6410685153"] : "Digital Marketing"}
                </Text>
                <Text
                  margin="0px 0px 0px 0px"
                  font="--m16"
                  text-align="left"
                  color="--primary"
                  sm-font="--m14"
                >
                  50%
                </Text>
              </Box>
              <Box
                margin="10px 0px 0px 0px"
                height="4px"
                background="--color-lightD1"
                border-radius="0px 100px 100px 0px"
              >
                <Box
                  height="4px"
                  width="250px"
                  background="--primary"
                  border-radius="100px 0px 0px 100px"
                  md-width="50%"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Section> */}
      <Section
        align-items="center"
        justify-content="center"
        padding="80px 0 80px 0"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
          flex-direction="column"
          sm-flex-direction="column"
          sm-align-items="center"
          md-flex-direction="column"
          align-items="center"
          justify-content="center"
        />
        <Box
          display="flex"
          align-items="center"
          justify-content="center"
          width="fit-content"
          border-width="0 0 1.4px 0"
          border-color="--color-lightgrey"
          border-style="solid"
          padding="0px 16px 0px 16px"
          flex-direction="column"
          margin="0px 0px 70px 0px"
          md-margin="0px 0px 30px 0px"
          lg-margin="0px 0px 40px 0px"
        >
          <Text
            font="--headline2"
            text-align="center"
            className="hoverText"
            width="fit-content"
            md-font="--headline3"
            sm-font="--headline4"
            sm-margin="12px 0px 12px 0px"
            id="8483476542"
            onClick={() => isSideBarOpen("8483476542")}
          >
            {data ? data["8483476542"] : "RESUME"}
          </Text>
          <Hr
            min-width="80%"
            margin="0px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            color="--primary"
            background="--primary"
            border-color="--color-primary"
            border-width="1.4px 0 0 0"
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          padding="40px 40px 40px 40px"
          border-width="3px"
          border-style="solid"
          border-color="--color-secondary"
          border-radius="8px"
          lg-padding="20px 20px 20px 20px"
          sm-flex-direction="column"
        >
          <Box
            width="50%"
            sm-width="100%"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="20px 0px 50px 30px"
            margin="0px 32px 0px 0px"
            box-shadow="0 0 10px 4px rgba(0, 0, 0, 0.08)"
            border-radius="6px"
            lg-padding="12px 20px 25px 20px"
            md-margin="0px 24px 0px 0px"
            sm-margin="0px 0px 24px 0px"
          >
            <Text
              font="--headline2"
              text-align="left"
              lg-width="100%"
              className="hoverText"
              lg-font="--headline3"
              md-font="--headline4"
              sm-font="--sm22"
              id="2174309875"
              onClick={() => isSideBarOpen("2174309875")}
            >
              {data ? data["2174309875"] : "Experience"}
            </Text>
            <Box
              margin="16px 0px 0px 0px"
              width="80%"
              lg-width="80%"
              md-display="flex"
              md-flex-direction="column"
              md-align-items="center"
              md-justify-content="center"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 5px"
                font="--sm22"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                lg-font="--base1"
                sm-font="--b14"
                id="2739258978"
                onClick={() => isSideBarOpen("2739258978")}
              >
                {data ? data["2739258978"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--m16"
                lg-margin="6px 0px 4px 5px"
                sm-font="--m12"
                id="3221288759"
                onClick={() => isSideBarOpen("3221288759")}
              >
                {data ? data["3221288759"] : "RESUME"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--r14"
                sm-font="--r12"
                id="1410542876"
                onClick={() => isSideBarOpen("1410542876")}
              >
                {data ? data["1410542876"] : "RESUME"}
              </Text>
              {/* <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--grey"
                md-font="--r12"
                sm-font="--r10"
                id="9316060928"
                onClick={() => isSideBarOpen("9316060928")}
              >
                {data ? data["9316060928"] : "RESUME"}
              </Text> */}
            </Box>
            <Box
              margin="16px 0px 0px 0px"
              width="80%"
              lg-width="80%"
              md-display="flex"
              md-flex-direction="column"
              md-align-items="flex-end"
              md-justify-content="center"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 5px"
                font="--sm22"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                lg-font="--base1"
                sm-font="--b14"
                id="9365834794"
                onClick={() => isSideBarOpen("9365834794")}
              >
                {data ? data["9365834794"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--m16"
                lg-margin="6px 0px 4px 5px"
                sm-font="--m12"
                id="5373604003"
                onClick={() => isSideBarOpen("5373604003")}
              >
                {data ? data["5373604003"] : "RESUME"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--r14"
                sm-font="--r12"
                id="6373781806"
                onClick={() => isSideBarOpen("6373781806")}
              >
                {data ? data["6373781806"] : "RESUME"}
              </Text>
              {/* <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--grey"
                md-font="--r12"
                sm-font="--r10"
                id="6597251067"
                onClick={() => isSideBarOpen("6597251067")}
              >
                {data ? data["6597251067"] : "RESUME"}
              </Text> */}
            </Box>
            <Box
              margin="16px 0px 0px 0px"
              width="80%"
              lg-width="80%"
              md-display="flex"
              md-flex-direction="column"
              md-align-items="center"
              md-justify-content="center"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 5px"
                font="--sm22"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                lg-font="--base1"
                sm-font="--b14"
                id="1312948690"
                onClick={() => isSideBarOpen("1312948690")}
              >
                {data ? data["1312948690"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--m16"
                lg-margin="6px 0px 4px 5px"
                sm-font="--m12"
                id="1819678995"
                onClick={() => isSideBarOpen("1819678995")}
              >
                {data ? data["1819678995"] : "RESUME"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--r14"
                sm-font="--r12"
                id="4098890361"
                onClick={() => isSideBarOpen("4098890361")}
              >
                {data ? data["4098890361"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--grey"
                md-font="--r12"
                sm-font="--r10"
                id="0894054659"
                onClick={() => isSideBarOpen("0894054659")}
              >
                {data ? data["0894054659"] : "RESUME"}
              </Text>
            </Box>
          </Box>
          <Box
            width="50%"
            sm-width="100%"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            padding="20px 0px 50px 30px"
            box-shadow="0 0 10px 4px rgba(0, 0, 0, 0.08)"
            border-radius="6px"
            lg-padding="12px 20px 25px 20px"
          >
            <Text
              font="--headline2"
              text-align="left"
              lg-width="100%"
              className="hoverText"
              md-margin="16px 0px 0px 0px"
              lg-font="--headline3"
              md-font="--headline4"
              sm-font="--sm22"
              id="4700946632"
              onClick={() => isSideBarOpen("4700946632")}
            >
              {data ? data["4700946632"] : "RESUME"}
            </Text>
            <Box
              margin="16px 0px 0px 0px"
              width="80%"
              lg-width="80%"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 5px"
                font="--sm22"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                lg-font="--base1"
                sm-font="--b14"
                id="3446196836"
                onClick={() => isSideBarOpen("3446196836")}
              >
                {data ? data["3446196836"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                md-margin="2px 0px 0px 5px"
                lg-font="--m16"
                lg-margin="6px 0px 4px 5px"
                sm-font="--m12"
                id="2856472851"
                onClick={() => isSideBarOpen("2856472851")}
              >
                {data ? data["2856472851"] : "RESUME"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--r14"
                sm-font="--r12"
                id="7632325322"
                onClick={() => isSideBarOpen("7632325322")}
              >
                {data ? data["7632325322"] : "RESUME"}
              </Text>
            </Box>
            <Box
              margin="16px 0px 0px 0px"
              width="80%"
              lg-width="80%"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 5px"
                font="--sm22"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                lg-font="--base1"
                sm-font="--b14"
                id="9666961557"
                onClick={() => isSideBarOpen("9666961557")}
              >
                {data ? data["9666961557"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                md-margin="2px 0px 0px 5px"
                lg-font="--m16"
                lg-margin="6px 0px 4px 5px"
                sm-font="--m12"
                id="3336131070"
                onClick={() => isSideBarOpen("3336131070")}
              >
                {data ? data["3336131070"] : "RESUME"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--r14"
                sm-font="--r12"
                id="6972322799"
                onClick={() => isSideBarOpen("6972322799")}
              >
                {data ? data["6972322799"] : "RESUME"}
              </Text>
            </Box>
            <Box
              margin="30px 0px 0px 0px"
              width="80%"
              lg-width="80%"
              sm-width="100%"
            >
              <Text
                margin="0px 0px 0px 5px"
                font="--sm22"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                lg-font="--base1"
                sm-font="--b14"
                id="4454039336"
                onClick={() => isSideBarOpen("4454039336")}
              >
                {data ? data["4454039336"] : "RESUME"}
              </Text>
              <Text
                margin="6px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                md-margin="2px 0px 0px 5px"
                lg-font="--m16"
                lg-margin="6px 0px 4px 5px"
                sm-font="--m12"
                id="6280427718"
                onClick={() => isSideBarOpen("6280427718")}
              >
                {data ? data["6280427718"] : "RESUME"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--r14"
                sm-font="--r12"
                id="0866821826"
                onClick={() => isSideBarOpen("0866821826")}
              >
                {data ? data["0866821826"] : "RESUME"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      {/* <Section
        align-items="center"
        justify-content="center"
        padding="80px 0 80px 0"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          display="flex"
          flex-direction="column"
          align-items="center"
        />
        <Box
          display="flex"
          align-items="center"
          justify-content="center"
          border-width="0 0 1.4px 0"
          border-color="--color-lightgrey"
          border-style="solid"
          padding="0px 16px 0px 16px"
          flex-direction="column"
          margin="0px 0px 70px 0px"
          md-margin="0px 0px 30px 0px"
          width="fit-content"
          lg-margin="0px 0px 40px 0px"
          sm-margin="0px 0px 24px 0px"
        >
          <Text
            font="--headline2"
            text-align="center"
            className="hoverText"
            width="fit-content"
            md-font="--headline3"
            sm-font="--headline4"
            sm-margin="12px 0px 12px 0px"
            id="5778519493"
            onClick={() => isSideBarOpen("5778519493")}
          >
            {data ? data["5778519493"] : " FEATURED WORK"}
          </Text>
          <Hr
            min-width="80%"
            margin="0px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            color="--primary"
            background="--primary"
            border-color="--color-primary"
            border-width="1.4px 0 0 0"
          />
        </Box>
        <Box
          width="100%"
          display="grid"
          grid-gap="35px"
          grid-template-columns="repeat(2, 1fr)"
          lg-width="100%"
          sm-grid-template-columns="repeat(1, 1fr)"
        >
          <LinkBox
            box-shadow="0 0 10px 1px rgba(0, 0, 0, 0.17)"
            width="100%"
            position="relative"
            border-radius="8px"
          >
            <Image
              src={
                data
                  ? data["5657894034"]
                  : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
              }
              onClick={() => isSideBarOpen("5657894034")}
              width="100%"
              height="500px"
              md-height="300px"
              className="hoverText"
              lg-height="450px"
              object-fit="cover"
              sm-height="280px"
              id="5657894034"
              border-radius="8px"
            />
            <Box
              height="140px"
              position="absolute"
              width="400px"
              bottom="50px"
              background="--color-secondary"
              padding="20px 20px 20px 20px"
              display="flex"
              align-items="center"
              justify-content="center"
              flex-direction="column"
              top="auto"
              right="auto"
              left="0px"
              md-width="250px"
              md-height="fit-content"
              lg-width="350px"
              sm-width="300px"
              md-align-items="flex-start"
              md-justify-content="center"
              md-bottom="10px"
              md-padding="15px 15px 15px 15px"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--subheadline"
                text-align="left"
                width="100%"
                md-width="fit-content"
                sm-width="100%"
                className="hoverText"
                color="--light"
                md-font="--lead"
                sm-font="--m18"
                id="5151517077"
                onClick={() => isSideBarOpen("5151517077")}
              >
                {data ? data["5151517077"] : "Web Design"}
              </Text>
              <Text
                margin="5px 0px 0px 0px"
                font="--r18"
                text-align="left"
                className="hoverText"
                color="--light"
                md-font="--r12"
                sm-font="--r10"
                id="4901150437"
                onClick={() => isSideBarOpen("4901150437")}
              >
                {data
                  ? data["4901150437"]
                  : "Refers to the process of creating and designing the visual appearance, layout, and functionality of websites."}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            border-radius="8px"
            box-shadow="0 0 10px 2px rgba(0, 0, 0, 0.23)"
            width="100%"
            position="relative"
          >
            <Image
              border-radius="8px"
              src={
                data
                  ? data["2481894748"]
                  : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
              }
              onClick={() => isSideBarOpen("2481894748")}
              width="100%"
              height="500px"
              md-height="300px"
              className="hoverText"
              lg-height="450px"
              object-fit="cover"
              sm-height="280px"
              id="2481894748"
            />
            <Box
              height="140px"
              position="absolute"
              width="400px"
              bottom="50px"
              background="--color-secondary"
              padding="20px 20px 20px 20px"
              display="flex"
              align-items="center"
              justify-content="center"
              flex-direction="column"
              top="auto"
              right="auto"
              left="0px"
              md-width="250px"
              md-height="fit-content"
              lg-width="350px"
              sm-width="300px"
              md-align-items="flex-start"
              md-justify-content="center"
              md-bottom="10px"
              md-padding="15px 15px 15px 15px"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--subheadline"
                text-align="left"
                width="100%"
                md-width="fit-content"
                sm-width="100%"
                className="hoverText"
                color="--light"
                md-font="--lead"
                sm-font="--m18"
                id="7518745989"
                onClick={() => isSideBarOpen("7518745989")}
              >
                {data ? data["7518745989"] : "Sketch"}
              </Text>
              <Text
                margin="5px 0px 0px 0px"
                font="--r18"
                text-align="left"
                className="hoverText"
                color="--light"
                md-font="--r12"
                sm-font="--r10"
                id="0830782483"
                onClick={() => isSideBarOpen("0830782483")}
              >
                {data
                  ? data["0830782483"]
                  : "Widely used by web and graphic designers for creating user interfaces, wireframes, and prototypes."}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            box-shadow="0 0 10px 1px rgba(0, 0, 0, 0.17)"
            width="100%"
            position="relative"
            border-radius="8px"
          >
            <Image
              border-radius="8px"
              src={
                data
                  ? data["9025575284"]
                  : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
              }
              onClick={() => isSideBarOpen("9025575284")}
              width="100%"
              height="500px"
              md-height="300px"
              className="hoverText"
              lg-height="450px"
              object-fit="cover"
              sm-height="280px"
              id="9025575284"
            />
            <Box
              height="140px"
              position="absolute"
              width="400px"
              bottom="50px"
              background="--color-secondary"
              padding="20px 20px 20px 20px"
              display="flex"
              align-items="center"
              justify-content="center"
              flex-direction="column"
              top="auto"
              right="auto"
              left="0px"
              md-width="250px"
              md-height="fit-content"
              lg-width="350px"
              sm-width="300px"
              font="--r18"
              color="--light"
              md-align-items="flex-start"
              md-justify-content="center"
              md-bottom="10px"
              md-padding="15px 15px 15px 15px"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--subheadline"
                text-align="left"
                width="100%"
                md-width="fit-content"
                sm-width="100%"
                className="hoverText"
                color="--light"
                md-font="--lead"
                sm-font="--m18"
                id="4070863606"
                onClick={() => isSideBarOpen("4070863606")}
              >
                {data ? data["4070863606"] : "UX Research"}
              </Text>
              <Text
                margin="5px 0px 0px 0px"
                font="--r18"
                text-align="left"
                className="hoverText"
                color="--light"
                md-font="--r12"
                sm-font="--r10"
                id="2492217035"
                onClick={() => isSideBarOpen("2492217035")}
              >
                {data
                  ? data["2492217035"]
                  : "Gathering data through various methods to guide design decisions and ensure that the final product meets user expectations."}
              </Text>
            </Box>
          </LinkBox>
          <LinkBox
            box-shadow="0 0 10px 1px rgba(0, 0, 0, 0.17)"
            width="100%"
            position="relative"
            border-radius="8px"
          >
            <Image
              border-radius="8px"
              src={
                data
                  ? data["5733248566"]
                  : "https://uploads.quarkly.io/6459cc90bf72b300207f117c/images/Left%20image.png?v=2023-05-09T13:20:01.891Z"
              }
              onClick={() => isSideBarOpen("5733248566")}
              width="100%"
              height="500px"
              md-height="300px"
              className="hoverText skinButton"
              lg-height="450px"
              object-fit="cover"
              sm-height="280px"
              id="5733248566"
            />
            <Box
              height="140px"
              position="absolute"
              width="400px"
              bottom="50px"
              background="--color-secondary"
              padding="20px 20px 20px 20px"
              display="flex"
              align-items="center"
              justify-content="center"
              flex-direction="column"
              top="auto"
              right="auto"
              left="0px"
              md-width="250px"
              md-height="fit-content"
              lg-width="350px"
              sm-width="300px"
              md-align-items="flex-start"
              md-justify-content="center"
              md-bottom="10px"
              md-padding="15px 15px 15px 15px"
            >
              <Text
                margin="0px 0px 0px 0px"
                font="--subheadline"
                text-align="left"
                width="100%"
                md-width="fit-content"
                sm-width="100%"
                className="hoverText"
                color="--light"
                md-font="--lead"
                sm-font="--m18"
                id="4099583303"
                onClick={() => isSideBarOpen("4099583303")}
              >
                {data ? data["4099583303"] : "App Design"}
              </Text>
              <Text
                margin="5px 0px 0px 0px"
                font="--r18"
                text-align="left"
                className="hoverText"
                color="--light"
                md-font="--r12"
                sm-font="--r10"
                id="7517898077"
                onClick={() => isSideBarOpen("7517898077")}
              >
                {data
                  ? data["7517898077"]
                  : "App design refers to the process of creating the visual and interactive interface of a mobile application."}
              </Text>
            </Box>
          </LinkBox>
        </Box>
      </Section> */}
      {/* <Section
        padding="80px 0 80px 0"
        className="c-lightgrey"
        align-items="center"
        justify-content="center"
        md-padding="50px 0 80px 0"
        sm-padding="40px 0px 40px 0px"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          flex-wrap="wrap"
          margin="0px auto 0px auto"
        />
        <Box
          display="flex"
          align-items="center"
          flex-direction="column"
          justify-content="center"
          margin="0px 0px 70px 0px"
          width="100%"
          sm-margin="0px 0px 24px 0px"
          md-margin="0px 0px 30px 0px"
        >
          <Box
            display="flex"
            align-items="center"
            justify-content="center"
            width="fit-content"
            border-width="0 0 1.4px 0"
            border-color="--color-lightgrey"
            border-style="solid"
            padding="0px 16px 0px 16px"
            flex-direction="column"
            margin="0px 0px 20px 0px"
            sm-margin="0px 0px 16px 0px"
          >
            <Text
              font="--headline2"
              text-align="center"
              className="hoverText"
              width="fit-content"
              md-font="--headline3"
              sm-font="--headline4"
              sm-margin="12px 0px 12px 0px"
              id="1174273290"
              onClick={() => isSideBarOpen("1174273290")}
            >
              {data ? data["1174273290"] : "REVIEWS"}
            </Text>
            <Hr
              min-width="80%"
              margin="0px 0px 0px 0px"
              padding="0px 0px 0px 0px"
              color="--primary"
              background="--primary"
              border-color="--color-primary"
              border-width="1.4px 0 0 0"
            />
          </Box>
          <Text
            margin="0px 0px 0px 0px"
            color="--dark"
            text-align="center"
            font="--subheadline"
            md-font="--r16"
            className="hoverText"
            id="0561336230"
            onClick={() => isSideBarOpen("0561336230")}
          >
            {data
              ? data["0561336230"]
              : "Sign up with your email address to receive news and updates."}
          </Text>
        </Box>
        <Swiper
          observer={true}
          observeParents={true}
          modules={[Navigation]}
          className="mySwiper "
          autoplay={{
            delay: "1000",
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
          }}
          breakpoints={{
            1536: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3.5,
              spaceBetween: 20,
            },
            991: {
              slidesPerView: 1.1,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 1.1,
              spaceBetween: 12,
            },
            425: {
              slidesPerView: 1.1,
              spaceBetween: 12,
            },
            325: {
              slidesPerView: 1.1,
              spaceBetween: 12,
            },
          }}
        >
          <div class="swiper-button image-swiper-button-prev bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
            </svg>
          </div>
          <div class="swiper-button image-swiper-button-next bg-white rounded-full p-1 cursor-pointer z-10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path>
            </svg>
          </div>
          <SwiperSlide>
            <Box
              margin="0px 10px 0px 0px"
              lg-margin="0px 0px 0px 0px"
              lg-padding="0px 15px 0px 0px"
              display="flex"
              flex-direction="column"
              padding="20px 20px 20px 20px"
              border-width="1px"
              border-style="solid"
              border-color="--color-secondary"
              md-padding="10px 20px 10px 20px"
              border-radius="8px"
            >
              <Text
                margin="0px 0px 0px 0px"
                color="--color-secondary"
                font="--headline1"
                md-font="--headline2"
                md-height="40px"
              >
                “
              </Text>
              <Text
                margin="0px 0px 60px 0px"
                color="--dark"
                font="--lead"
                lg-margin="0px 0px 30px 0px"
                sm-margin="0px 0px 30px 0px"
                flex="1 0 auto"
                md-font="--r12"
                className=" hoverText"
                id="1953858551"
                onClick={() => isSideBarOpen("1953858551")}
              >
                {data ? data["1953858551"] : " no data"}
                
              </Text>
              <Box
                display="flex"
                margin="0px 17px 0px 0px"
                align-items="flex-start"
                md-align-items="center"
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["0159278061"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                  }
                  onClick={() => isSideBarOpen("0159278061")}
                  id="0159278061"
                  className="hoverText"
                  border-radius="50px"
                  background="#ececec"
                  margin="0px 17px 0px 0px"
                />
                <Box md-width="80%">
                  <Text
                    color="--dark"
                    font="--sm22"
                    margin="0px 0px 2px 0px"
                    md-width="100%"
                    md-font="--m14"
                    className="hoverText"
                    id="2945670410"
                    onClick={() => isSideBarOpen("2945670410")}
                  >
                    {data ? data["2945670410"] : " no data"}
                  </Text>
                  <Text
                    color="--grey"
                    font="--base"
                    margin="0px 0px 0px 0px"
                    opacity=".5"
                    md-font="--r12"
                    md-color="--grey"
                    className="hoverText"
                    id="5556311618"
                    onClick={() => isSideBarOpen("5556311618")}
                  >
                    {data ? data["5556311618"] : " no data"}
                  </Text>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box
              margin="0px 10px 0px 0px"
              lg-margin="0px 0px 0px 0px"
              lg-padding="0px 0px 0px 15px"
              display="flex"
              border-radius="8px"
              flex-direction="column"
              padding="20px 20px 20px 20px"
              border-width="1px"
              border-style="solid"
              border-color="--color-secondary"
              md-padding="10px 20px 10px 20px"
            >
              <Text
                margin="0px 0px 0px 0px"
                color="--color-secondary"
                font="--headline1"
                md-font="--headline2"
                md-height="40px"
              >
                “
              </Text>
              <Text
                margin="0px 0px 60px 0px"
                color="--dark"
                font="--lead"
                lg-margin="0px 0px 30px 0px"
                sm-margin="0px 0px 30px 0px"
                flex="1 0 auto"
                md-font="--r12"
                className="hoverText"
                onClick={() => isSideBarOpen("6101423591")}
                id="6101423591"
              >
                {data ? data["6101423591"] : "no data"}
              </Text>
              <Box
                display="flex"
                margin="0px 17px 0px 0px"
                align-items="flex-start"
                md-align-items="center"
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["4160394894"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                  }
                  onClick={() => isSideBarOpen("4160394894")}
                  id="4160394894"
                  className="hoverText"
                  border-radius="50px"
                  background="#ececec"
                  margin="0px 17px 0px 0px"
                />
                <Box md-width="80%">
                  <Text
                    color="--dark"
                    font="--sm22"
                    margin="0px 0px 2px 0px"
                    md-width="100%"
                    md-font="--m14"
                    className="hoverText"
                    onClick={() => isSideBarOpen("1431400592")}
                    id="1431400592"
                  >
                    {data ? data["1431400592"] : "no data"}
                  </Text>
                  <Text
                    color="--grey"
                    font="--base"
                    margin="0px 0px 0px 0px"
                    opacity=".5"
                    md-font="--r12"
                    md-color="--grey"
                    className="hoverText"
                    onClick={() => isSideBarOpen("1259182109")}
                    id="1259182109"
                  >
                    {data ? data["1259182109"] : "no data"}
                  </Text>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box
              margin="0px 10px 0px 0px"
              lg-margin="0px 0px 0px 0px"
              lg-padding="0px 0px 0px 15px"
              display="flex"
              border-radius="8px"
              flex-direction="column"
              padding="20px 20px 20px 20px"
              border-width="1px"
              border-style="solid"
              border-color="--color-secondary"
              md-padding="10px 20px 10px 20px"
            >
              <Text
                margin="0px 0px 0px 0px"
                color="--color-secondary"
                font="--headline1"
                md-font="--headline2"
                md-height="40px"
              >
                “
              </Text>
              <Text
                margin="0px 0px 60px 0px"
                color="--dark"
                font="--lead"
                lg-margin="0px 0px 30px 0px"
                sm-margin="0px 0px 30px 0px"
                flex="1 0 auto"
                md-font="--r12"
                className="hoverText"
                onClick={() => isSideBarOpen("2939931545")}
                id="2939931545"
              >
                {data ? data["2939931545"] : "no data"}
              </Text>
              <Box
                display="flex"
                margin="0px 17px 0px 0px"
                align-items="flex-start"
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["9816012268"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                  }
                  onClick={() => isSideBarOpen("9816012268")}
                  id="9816012268"
                  className="hoverText"
                  border-radius="50px"
                  background="#ececec"
                  margin="0px 17px 0px 0px"
                />
                <Box>
                  <Text
                    color="--dark"
                    md-font="--m14"
                    font="--sm22"
                    margin="0px 0px 2px 0px"
                    className="hoverText"
                    onClick={() => isSideBarOpen("6872473681")}
                    id="6872473681"
                  >
                    {data ? data["6872473681"] : "no data"}
                  </Text>
                  <Text
                    color="--grey"
                    font="--base"
                    margin="0px 0px 0px 0px"
                    opacity=".5"
                    md-font="--r12"
                    md-color="--grey"
                    className="hoverText"
                    onClick={() => isSideBarOpen("9082708859")}
                    id="9082708859"
                  >
                    {data ? data["9082708859"] : "no data"}
                  </Text>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Box
              margin="0px 10px 0px 0px"
              lg-margin="0px 0px 0px 0px"
              lg-padding="0px 0px 0px 15px"
              display="flex"
              border-radius="8px"
              flex-direction="column"
              padding="20px 20px 20px 20px"
              border-width="1px"
              border-style="solid"
              border-color="--color-secondary"
              md-padding="10px 20px 10px 20px"
            >
              <Text
                margin="0px 0px 0px 0px"
                color="--color-secondary"
                font="--headline1"
                md-font="--headline2"
                md-height="40px"
              >
                “
              </Text>
              <Text
                margin="0px 0px 60px 0px"
                color="--dark"
                font="--lead"
                lg-margin="0px 0px 30px 0px"
                sm-margin="0px 0px 30px 0px"
                flex="1 0 auto"
                md-font="--r12"
                className="hoverText"
                onClick={() => isSideBarOpen("5557337889")}
                id="5557337889"
              >
                {data ? data["5557337889"] : "no data"}
              </Text>
              <Box
                display="flex"
                margin="0px 17px 0px 0px"
                align-items="flex-start"
                md-align-items="center"
              >
                <Image
                  width="64px"
                  height="64px"
                  src={
                    data
                      ? data["9290722745"]
                      : "https://uploads.quarkly.io/64815dfa8bd7e6001858b7a2/images/Frame%20484.png?v=2023-06-08T07:03:21.201Z"
                  }
                  onClick={() => isSideBarOpen("9290722745")}
                  id="9290722745"
                  className="hoverText"
                  border-radius="50px"
                  background="#ececec"
                  margin="0px 17px 0px 0px"
                />
                <Box>
                  <Text
                    color="--dark"
                    font="--sm22"
                    margin="0px 0px 2px 0px"
                    md-width="100%"
                    md-font="--m14"
                    className="hoverText"
                    onClick={() => isSideBarOpen("8784361373")}
                    id="8784361373"
                  >
                    {data ? data["8784361373"] : "no data"}
                  </Text>
                  <Text
                    color="--grey"
                    font="--base"
                    margin="0px 0px 0px 0px"
                    opacity=".5"
                    md-font="--r12"
                    md-color="--grey"
                    className="hoverText"
                    onClick={() => isSideBarOpen("3227149281")}
                    id="3227149281"
                  >
                    {data ? data["3227149281"] : "no data"}
                  </Text>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Section> */}
      {/* <Section
        align-items="center"
        justify-content="center"
        padding="80px 0 80px 0"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          display="flex"
          flex-direction="column"
          lg-flex-direction="column"
          lg-align-items="center"
          lg-justify-content="center"
          align-items="center"
          justify-content="center"
          margin="0px auto 0px auto"
        />
        <Box
          display="flex"
          align-items="center"
          justify-content="center"
          width="fit-content"
          border-width="0 0 1.4px 0"
          border-color="--color-lightgrey"
          border-style="solid"
          padding="0px 16px 0px 16px"
          flex-direction="column"
          margin="0px 0px 70px 0px"
          md-margin="0px 0px 30px 0px"
          sm-margin="0px 0px 24px 0px"
        >
          <Text
            font="--headline2"
            text-align="center"
            className="hoverText"
            width="fit-content"
            md-font="--headline3"
            sm-margin="12px 0px 12px 0px"
            sm-font="--headline4"
            id="2070669596"
            onClick={() => isSideBarOpen("2070669596")}
          >
            {data ? data["2070669596"] : "BLOGS"}
          </Text>
          <Hr
            min-width="80%"
            margin="0px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            color="--primary"
            background="--primary"
            border-color="--color-primary"
            border-width="1.4px 0 0 0"
          />
        </Box>
        <MyBlogs />
      </Section> */}
      <Section
        id="enquirenow"
        align-items="center"
        justify-content="center"
        padding="80px 0 80px 0"
        md-padding="50px 0 50px 0"
        sm-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          display="flex"
          flex-direction="column"
          padding="0px 78px 0px 78px"
          align-items="center"
          justify-content="center"
          margin="0px auto 0px auto"
          lg-padding="0px 0px 0px 0px"
        />
        <Box
          display="flex"
          align-items="center"
          justify-content="center"
          width="fit-content"
          border-width="0 0 1.4px 0"
          border-color="--color-lightgrey"
          border-style="solid"
          padding="0px 16px 0px 16px"
          flex-direction="column"
          margin="0px 0px 70px 0px"
          md-margin="0px 0px 30px 0px"
          lg-margin="0px 0px 40px 0px"
          sm-margin="0px 0px 24px 0px"
        >
          <Text
            font="--headline2"
            text-align="center"
            className="hoverText"
            width="fit-content"
            md-font="--headline3"
            sm-margin="12px 0px 12px 0px"
            sm-font="--headline4"
            id="3348726758"
            onClick={() => isSideBarOpen("3348726758")}
          >
            {data ? data["3348726758"] : "CONTACT ME"}
          </Text>
          <Hr
            min-width="80%"
            margin="0px 0px 0px 0px"
            padding="0px 0px 0px 0px"
            color="--primary"
            background="--primary"
            border-color="--color-primary"
            border-width="1.4px 0 0 0"
          />
        </Box>
        <Box
          display="flex"
          width="100%"
          border-width="0px"
          border-style="none"
          border-color="--color-primary"
          border-radius="8px"
          sm-flex-direction="column"
        >
          <Box
            width="50%"
            md-width="100%"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            margin="0px 40px 0px 0px"
            border-radius="6px"
            sm-margin="0px 0px 40px 0px"
          >
            <Text
              font="--headline3"
              text-align="left"
              lg-width="100%"
              className="hoverText"
              md-font="--b18"
              lg-margin="0px 0px 16px 0px"
              lg-font="--headline3"
              md-margin="0px 0px 20px 0px"
              sm-font="--m16"
              id="2920160384"
              onClick={() => isSideBarOpen("")}
            >
              {data ? data["2920160384"] : "Just say Hello"}
            </Text>
            <Input
              display="block"
              placeholder-color="LightGray"
              background="white"
              placeholder="Your Name"
              color="--dark"
              width="100%"
              height="60px"
              md-font="--r14"
              md-height="45px"
              lg-height="56px"
              lg-font="--r16"
              font="--r18"
              sm-font="--r12"
              sm-height="40px"
              className="outlineInput"
              id="4710176655"
              value={name}
              onChange={handleNameChange}
            />
            {/* <Box
              width="100%"
              display="flex"
              align-items="center"
              justify-content="center"
              id="4949568652"
              onClick={() => isSideBarOpen("4949568652")}
            >
              <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--lightgrey"
                lg-font="--m16"
                md-font="--m14"
                sm-font="--m12"
                sm-margin="6px 0px 0px 0px"
              >
                {data ? data["4949568652"] : "Your Name Error"}
              </Text>
            </Box> */}
            <Input
              display="block"
              placeholder-color="LightGray"
              background="white"
              placeholder="Email ID"
              color="--dark"
              width="100%"
              height="60px"
              margin="20px 0px 0px 0px"
              md-font="--r14"
              md-height="45px"
              lg-height="56px"
              lg-font="--r16"
              font="--r18"
              sm-font="--r12"
              className="outlineInput"
              sm-height="40px"
              sm-margin="12px 0px 0px 0px"
              id="0627192981"
              value={email}
              onChange={handleemailChange}
            />
            {/* <Box
              width="100%"
              display="flex"
              align-items="center"
              justify-content="center"
              id="4661542326"
              onClick={() => isSideBarOpen("4661542326")}
            >
              <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--lightgrey"
                lg-font="--m16"
                md-font="--m14"
                sm-font="--m12"
                sm-margin="6px 0px 0px 0px"
              >
                {data ? data["4661542326"] : "Your Name Error"}
              </Text>
            </Box> */}
            <Input
              display="block"
              placeholder-color="LightGray"
              background="white"
              placeholder="Phone Number"
              color="--dark"
              width="100%"
              height="60px"
              margin="20px 0px 0px 0px"
              md-font="--r14"
              md-height="45px"
              lg-height="56px"
              lg-font="--r16"
              font="--r18"
              sm-font="--r12"
              sm-height="40px"
              sm-margin="12px 0px 0px 0px"
              id="2746231398"
              className="outlineInput"
              value={phone}
              onChange={handlephoneChange}
            />
            {/* <Box
              width="100%"
              display="flex"
              align-items="center"
              justify-content="center"
              id="6030290576"
              onClick={() => isSideBarOpen("6030290576")}
            >
              <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--lightgrey"
                lg-font="--m16"
                md-font="--m14"
                sm-font="--m12"
                sm-margin="6px 0px 0px 0px"
              >
                {data ? data["6030290576"] : "Your Name Error"}
              </Text>
            </Box> */}
            <Input
              display="flex"
              placeholder-color="LightGray"
              background="white"
              placeholder="Your Message"
              color="--dark"
              width="100%"
              height="100px"
              margin="20px 0px 0px 0px"
              align-content="flex-start"
              align-self="auto"
              flex="0 1 auto"
              required={false}
              md-font="--r14"
              md-height="80px"
              as="textarea"
              font="--r18"
              lg-font="--r16"
              sm-font="--r12"
              sm-height="75px"
              sm-margin="12px 0px 0px 0px"
              id="2871970367"
              className="outlineInput"
              value={message}
              onChange={handlemessageChange}
            />
            {/* <Box
              width="100%"
              display="flex"
              align-items="center"
              justify-content="center"
              id="8772346531"
            >
              <Text
                margin="6px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--lightgrey"
                lg-font="--m16"
                md-font="--m14"
                sm-font="--m12"
                sm-margin="6px 0px 0px 0px"
                onClick={() => isSideBarOpen("8772346531")}
              >
                {data ? data["8772346531"] : "Your Name Error"}
              </Text>
            </Box> */}
            <Box
              id="2325682016"
              onClick={() => isSideBarOpen("2325682016")}
              className="hoverText"
            >
              <Button
                margin="30px 0px 0px 0px"
                padding="20px 40px 20px 40px"
                border-radius="8px"
                color="white"
                md-font="--m12"
                md-padding="12px 24px 12px 24px"
                md-margin="40px 0px 0px 0px"
                sm-margin="32px 0px 0px 0px"
                onClick={handleSubmit}
              >
                {data ? data["2325682016"] : "Send Message"}
              </Button>
            </Box>
          </Box>
          <Box
            width="50%"
            sm-width="100%"
            md-width="100%"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-start"
            border-radius="6px"
          >
            <Text
              font="--headline3"
              text-align="left"
              lg-width="100%"
              className="hoverText"
              md-font="--b18"
              md-margin="0 0px 16px 0px"
              lg-margin="0px 0px 16px 0px"
              lg-font="--headline3"
              sm-font="--m16"
              sm-margin="0px 0px 12px 0px"
              id="5328159138"
              onClick={() => isSideBarOpen("5328159138")}
            >
              {data ? data["5328159138"] : "Contact Info"}
            </Text>
            <Text
              margin="6px 0px 0px 5px"
              font="--base"
              text-align="left"
              width="100%"
              lg-width="100%"
              className="hoverText"
              color="--grey"
              lg-font="--r16"
              md-font="--r14"
              sm-margin="0px 0px 0px 0px"
              sm-font="--r12"
              id="1139375935"
              onClick={() => isSideBarOpen("1139375935")}
            >
              {data
                ? data["1139375935"]
                : "The Contact Us section is designed to facilitate communication between users and our organization."}
            </Text>
            <Box margin="16px 0px 0px 0px">
              <Text
                margin="0px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                md-font="--r16"
                lg-font="--base1"
                lg-margin="0px 0px 8px 5px"
                sm-font="--b14"
                sm-margin="0px 0px 8px 0px"
                id="5424959585"
                onClick={() => isSideBarOpen("5424959585")}
              >
                {data ? data["5424959585"] : "Email"}
              </Text>
              <a href="mailto:stanlasrado@gmail.com">
                {" "}
                <Text
                  margin="6px 0px 0px 5px"
                  font="--r18"
                  text-align="left"
                  width="100%"
                  lg-width="100%"
                  className="hoverText"
                  color="--dark"
                  lg-font="--m16"
                  md-font="--m14"
                  sm-font="--m12"
                  sm-margin="6px 0px 0px 0px"
                  id="3517788926"
                  onClick={() => isSideBarOpen("3517788926")}
                >
                  {data ? data["3517788926"] : "john@gmail.com"}
                </Text>
              </a>
              <a href="mailto:ryanlasrado@gmail.com">
                <Text
                  margin="6px 0px 0px 5px"
                  font="--r18"
                  text-align="left"
                  width="100%"
                  lg-width="100%"
                  className="hoverText"
                  color="--dark"
                  lg-font="--m16"
                  md-font="--m14"
                  sm-font="--m12"
                  sm-margin="6px 0px 0px 0px"
                >
                  ryanlasrado@gmail.com
                </Text>
              </a>
            </Box>
            <Box margin="16px 0px 0px 0px">
              <Text
                margin="0px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                md-font="--r16"
                lg-font="--base1"
                lg-margin="0px 0px 8px 5px"
                sm-font="--b14"
                sm-margin="0px 0px 8px 0px"
                id="1765283173"
                onClick={() => isSideBarOpen("1765283173")}
              >
                {data ? data["1765283173"] : "Phone"}
              </Text>
              <a href="tel:9821060616">
                {" "}
                <Text
                  margin="6px 0px 0px 5px"
                  font="--r18"
                  text-align="left"
                  width="100%"
                  lg-width="100%"
                  className="hoverText"
                  color="--dark"
                  lg-font="--m16"
                  md-font="--m14"
                  sm-font="--m12"
                  sm-margin="6px 0px 0px 0px"
                  id="2294855611"
                  onClick={() => isSideBarOpen("2294855611")}
                >
                  {data ? data["2294855611"] : "+91 9821060616"}
                </Text>
              </a>
              <a href="tel:9769088933">
                <Text
                  margin="6px 0px 0px 5px"
                  font="--r18"
                  text-align="left"
                  width="100%"
                  lg-width="100%"
                  className="hoverText mb-2"
                  color="--dark"
                  lg-font="--m16"
                  md-font="--m14"
                  sm-font="--m12"
                  sm-margin="6px 0px 0px 0px"
                  id="2570915092"
                  onClick={() => isSideBarOpen("2570915092")}
                >
                  {data ? data["2570915092"] : "+91 9769088933"}
                </Text>
              </a>
            </Box>
            <Box margin="16px 0px 0px 0px">
              <Text
                margin="0px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                md-font="--r16"
                lg-font="--base1"
                lg-margin="0px 0px 8px 5px"
                sm-font="--b14"
                sm-margin="0px 0px 8px 0px"
                id="0376275418"
                onClick={() => isSideBarOpen("0376275418")}
              >
                {data ? data["0376275418"] : "Address"}
              </Text>
              <Text
                margin="0px 0px 0px 5px"
                font="--r18"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--dark"
                lg-font="--m16"
                md-font="--m14"
                sm-font="--m12"
                sm-margin="0px 0px 0px 0px"
                id="3475376110"
                onClick={() => isSideBarOpen("3475376110")}
              >
                {data
                  ? data["3475376110"]
                  : "B-112, Madura Apartment, Marol, Andheri, Mumbi 40009"}
              </Text>
            </Box>

            <Box margin="16px 0px 0px 0px">
              <Text
                margin="0px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText mb-2"
                color="--color-secondary"
                md-font="--r16"
                lg-font="--base1"
                lg-margin="0px 0px 8px 5px"
                sm-font="--b14"
                sm-margin="0px 0px 8px 0px"
              >
                Phone:
              </Text>
              <a href="tel:+91 2235729814">
                <Text
                  margin="0px 0px 0px 5px"
                  font="--r18"
                  text-align="left"
                  width="100%"
                  lg-width="100%"
                  className="hoverText"
                  color="--dark"
                  lg-font="--m16"
                  md-font="--m14"
                  sm-font="--m12"
                  sm-margin="0px 0px 0px 0px"
                >
                  +91 2235729814
                </Text>
              </a>

              <a href="tel:+91 2235987483">
                <Text
                  margin="0px 0px 0px 5px"
                  font="--r18"
                  text-align="left"
                  width="100%"
                  lg-width="100%"
                  className="hoverText pt-1"
                  color="--dark"
                  lg-font="--m16"
                  md-font="--m14"
                  sm-font="--m12"
                  sm-margin="0px 0px 0px 0px"
                >
                  +91 2235987483
                </Text>
              </a>
            </Box>
            {/* <Box margin="16px 0px 0px 0px">
              <Text
                margin="0px 0px 0px 5px"
                font="--navigation"
                text-align="left"
                width="100%"
                lg-width="100%"
                className="hoverText"
                color="--color-secondary"
                md-font="--r16"
                lg-font="--base1"
                lg-margin="0px 0px 8px 5px"
                sm-font="--b14"
                sm-margin="0px 0px 8px 0px"
                id="8641331774"
                onClick={() => isSideBarOpen("8641331774")}
              >
                {data
                  ? data["8641331774"]
                  : "Visit My Social Profile And Get Connected"}
              </Text>
              <Box
                display="flex"
                justify-content="space-between"
                margin="20px 0px 0px 0px"
                md-width="283px"
                sm-margin="12px 0px 0px 0px"
                sm-justify-content="flex-start"
              >
                <Box
                  width="fit-content"
                  height="fit-content"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-dark"
                  border-radius="100px"
                  sm-margin="0px 12px 0px 0px"
                  id="7710923611"
                >
                  <Icon
                    category="ti"
                    icon={TiSocialLinkedin}
                    size="28px"
                    margin="14px 14px 14px 14px"
                    md-width="24px"
                    md-height="24px"
                    md-margin="8px 8px 8px 8px"
                    md-padding="0px 0px 0px 0px"
                    sm-height="20px"
                    sm-width="20px"
                  />
                </Box>
                <Box
                  width="fit-content"
                  height="fit-content"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-dark"
                  border-radius="100px"
                  sm-margin="0px 12px 0px 0px"
                  id="6234185002"
                >
                  <Icon
                    category="fi"
                    icon={FiInstagram}
                    size="28px"
                    margin="14px 14px 14px 14px"
                    md-width="24px"
                    md-height="24px"
                    md-margin="8px 8px 8px 8px"
                    md-padding="0px 0px 0px 0px"
                    sm-height="20px"
                    sm-width="20px"
                  />
                </Box>
                <Box
                  width="fit-content"
                  height="fit-content"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-dark"
                  border-radius="100px"
                  sm-margin="0px 12px 0px 0px"
                  id="0138615726"
                >
                  <Icon
                    category="ai"
                    icon={AiOutlineBehance}
                    size="28px"
                    margin="14px 14px 14px 14px"
                    md-width="24px"
                    md-height="24px"
                    md-margin="8px 8px 8px 8px"
                    md-padding="0px 0px 0px 0px"
                    sm-height="20px"
                    sm-width="20px"
                  />
                </Box>
                <Box
                  width="fit-content"
                  height="fit-content"
                  border-width="1px"
                  border-style="solid"
                  border-color="--color-dark"
                  border-radius="100px"
                  id="4373264920"
                >
                  <Icon
                    category="fi"
                    icon={FiTwitter}
                    size="28px"
                    margin="14px 14px 14px 14px"
                    md-width="24px"
                    md-height="24px"
                    md-margin="8px 8px 8px 8px"
                    md-padding="0px 0px 0px 0px"
                    sm-height="20px"
                    sm-width="20px"
                  />
                </Box>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64377e87d28fe100213859da"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
