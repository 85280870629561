import { combineReducers } from "redux";
import Cms_reducer from "./CMS/cms-reducer";
import BD_reducer from "./BrandDetails/bd-reducer";
import Menu_reducer from "./Menu/menu-reducer";
import Product_reducer from "./Product/prouct-reducer";
import userDetails from "./UserDetails/user-reducer";
import AuthDetails from "./auth/auth-reducer";
import BlogDetails from "./Blogs/blogs-reducer";
const rootReducer = combineReducers({
  cms: Cms_reducer,
  brandDetails: BD_reducer,
  menu: Menu_reducer,
  product: Product_reducer,
  userDetails: userDetails,
  AuthDetails: AuthDetails,
  BlogDetails: BlogDetails,
});

export default rootReducer;
