import React from "react";
import theme from "theme";
import {
  Theme,
  Text,
  Box,
  Image,
  LinkBox,
  Hr,
  Icon,
  Link,
  Button,
  Section,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
  setCmsActive,
} from "redux/CMS/cms-actions";
import {
  addSingleProduct,
  product_reducer,
  defaultImage,
  defaultVariantData,
} from "redux/Product/product-action";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
import { getProject } from "redux/UserDetails/user-actions";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { getAllBlogs } from "redux/Blogs/blogs-actions";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Blogs from "./components/Blogs";
export default () => {
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [selectedData, updateSelectedData] = useState("update");
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);

  console.log(BrandDetails, "outside in");
  let project = useSelector((state) => state.userDetails.project);
  let data = useSelector((state) => state.cms.pages["Work"]);
  let userData = useSelector((state) => state.cms.userData);
  let productData = useSelector((state) => state.product.product);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------

  useEffect(() => {
    dispatch(getBrandProfile());
    console.log("Home");
    setMounted(true);
    dispatch(
      getBrandProfile({
        projectId: project._id,
        projectName: project.projectName,
      })
    );
  }, [dispatch, setMounted]);
  console.log(data, "outside");
  console.log(productData, "hii");
  console.log(getBrandProfile, "checking");

  function applyThemeToDocument() {
    if (BrandDetails && BrandDetails.hasOwnProperty("primaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-primary",
        BrandDetails.primaryColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("secondayColor"))
      document.body.style.setProperty(
        "--qtheme-color-seconday",
        BrandDetails.secondayColor
      );

    if (BrandDetails && BrandDetails.hasOwnProperty("tirtiaryColor"))
      document.body.style.setProperty(
        "--qtheme-color-tirtiary",
        BrandDetails.tirtiaryColor
      );
  }
  applyThemeToDocument(theme);
  // if (mounted) {
  //   if (token === userData.token) {
  //     const ids = Object.keys(data);
  //     console.log(ids, "keys");

  //   }
  // }
  // let selectedData;
  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  function showProduct(data) {
    console.log("show product");
    dispatch(addSingleProduct(data));
    let defImage = data.productImageId[0].productImage;
    dispatch(defaultImage(defImage));
    let defVarint = data.variationId[0];
    dispatch(defaultVariantData(defVarint));
    let defAttributeName = data.productImageId[0].da;
    dispatch(defaultVariantData(defVarint));
    console.log(defImage, "hellos");
  }
  function getFavicon(value) {
    return "https://nexuscluster.blob.core.windows.net/server01/undefined/0.841612049515601.png";
  }
  const baseURL = process.env.REACT_APP_STORAGE_URL;
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"works"} />
      <Helmet>
        <title>Works</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        margin="20px 0 0px 0"
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          flex-direction="row"
          sm-align-items="center"
          sm-justify-content="center"
        />
        <Box
          margin="0px 60px 0px 0px"
          lg-margin="0px 24px 0px 0px"
          width="70%"
          md-margin="0px 0px 0px 0px"
          lg-width="100%"
          sm-display="flex"
          sm-flex-direction="column"
          sm-align-items="center"
          sm-justify-content="center"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            margin="0px 0px 32px 0px"
          >
            <Text
              font="--headline2"
              color="--dark"
              margin="0px 0px 0px 0px"
              className="hoverText"
              md-font="--headline3"
              sm-font="--headline4"
              id="9951337087"
              onClick={() => isSideBarOpen("9951337087")}
            >
              {data ? data["9951337087"] : "Fetured Blogs"}
            </Text>
          </Box>
          <Blogs />
        </Box>
        <Box width="30%" md-display="none" lg-width="35%" lg-display="none">
          <Box
            padding="35px 24px 35px 24px"
            font="--headline3"
            text-align="center"
            border-radius="4px"
            background="--light2"
          >
            <Text
              margin="0px 0px 18px 0px"
              font="--headline4"
              className="hoverText"
              id="1989157773"
              onClick={() => isSideBarOpen("1989157773")}
            >
              {data ? data["1989157773"] : " Get More Done Together With US"}
            </Text>
            <Text
              margin="0px 0px 55px 0px"
              font="--r18"
              className="hoverText"
              id="3889780749"
              onClick={() => isSideBarOpen("3889780749")}
            >
              {data
                ? data["3889780749"]
                : "  Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
            </Text>
            <Button
              className="hoverText SkinButton"
              id="0177870296"
              onClick={() => isSideBarOpen("0177870296")}
            >
              {data ? data["0177870296"] : "Contact"}
            </Button>
          </Box>
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}

      <RawHtml>
        <style place={"endOfHead"} rawKey={"64377e87d28fe100213859da"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
