import React,{ useEffect } from "react";
import Index from "./pages/home/Home";
import Work from "./pages/works/Work";
import Detail from "./pages/works/components/Blogoverview";
import Blog from "./pages/blogs/Blog";
import BlogOverview from "./pages/blogs/components/BlogOverview";
import About from "./pages/about/About";
// import Detail12 from "./pages/detail12";
import Contact from "./pages/contact/Contact";
// import Page404 from "./page404";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import { PersistGate } from "redux-persist/integration/react";
import FooterPortfolio from "./footer/Footer";
import Navigation from "./navbar/NavabarHeader";
import axios from "axios";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;
console.log("window", window.location);
export default () => {
const appendgtag = async () => {
		const headers = {
			"Content-Type": "application/json",
			"community-name": process.env.REACT_APP_COMMUNITY_NAME,
		};
		let { data } = await axios.post(
			process.env.REACT_APP_BASE_URL + "project/getProject",
			{},
			{
				headers: headers,
			}
		);
		console.log(data.data, data.data.gTag, "Yooo Anish");
		if (data.data && data.data.gTag) {
			let s = document.createElement("script");
			s.async = true;
			s.src = "https://www.googletagmanager.com/gtag/js?id=" + data.data.gTag;
			s.onload = () => {
				window.datalayer = window.datalayer || [];
				window.datalayer.push({
					"gtm.start": new Date().getTime(),
					event: "gtm.js",
					"js": new Date(),
					"config": data.data.gTag,
				});
			};
			document.getElementsByTagName("head")[0].appendChild(s);
		}
	};
	useEffect(() => {
		appendgtag();
	}, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Navigation />
          <Switch>
            <div>
              <GlobalStyles />
              <Route exact path="/" component={Index} />
              <Route exact path="/home" component={Index} />
              <Route exact path="/home/:token" component={Index} />
              <Route exact path="/about us" component={About} />
              <Route exact path="/about us/:token" component={About} />
              <Route exact name="work" path="/work" component={Work} />
              <Route exact name="work" path="/work/:token" component={Work} />
              <Route exact path="/detail Overview" component={Detail} />
              <Route exact path="/detail Overview/:token" component={Detail} />
              <Route
                exact
                name="Blog Categories"
                path="/Blog Categories"
                component={Blog}
              />
              <Route
                exact
                name="Blog Categories"
                path="/Blog Categories/:token"
                component={Blog}
              />
              <Route
                exact
                name="Blog Overview"
                path="/blogoverview"
                component={BlogOverview}
              />
              {/* <Route exact path="/detail-overview" component={Detail12} /> */}
              {/* <Route exact path="/button" component={Button} /> */}
              {/* <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/privacy/:token" component={Privacy} />
              <Route exact path="/terms" component={Terms} /> */}
              {/* <Route exact path="*" component={Page404} /> */}
              <Route exact path="/Contact Us" component={Contact} />
              <Route exact path="/Contact Us/:token" component={Contact} />
            </div>
          </Switch>
          <FooterPortfolio />
        </Router>
      </PersistGate>
    </Provider>
  )
};
