import React from "react";
import theme from "theme";
import {
  Theme,
  Image,
  Box,
  Text,
  LinkBox,
  Hr,
  Button,
  Section,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import { useState, useEffect } from "react";
import { MdSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getBrandProfile } from "redux/BrandDetails/bd-actions";
import { NavLink, useParams } from "react-router-dom";
import ContentUpdate from "components/CMS/ContentUpdate";
// import { addSingleBlog } from "redux/Blogs/blogs-reducer";
import { addSingleBlog } from "redux/Blogs/blogs-actions";
import { getAllBlogs } from "redux/Blogs/blogs-actions";
import {
  getCms,
  getElementById,
  getUser,
  setSelectedData,
} from "redux/CMS/cms-actions";
export default () => {
  let blogData = useSelector((state) => state.BlogDetails.Blog);
  let singleBlog = useSelector((state) => state.BlogDetails.addSingleBlog);
  let latestBlog = blogData.reverse();
  latestBlog.slice(0, 2);
  const [showElement, setShowElment] = useState(false);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  let data = useSelector((state) => state.cms.pages["Blog Overview"]);
  let project = useSelector((state) => state.userDetails.project);
  const BrandDetails = useSelector((state) => state.brandDetails.brandProfile);
  const [selectedData, updateSelectedData] = useState("update");
  const [isShow, setIsShow] = useState(false);
  let brandProfile = useSelector((state) => state.brandDetails.brandProfile);
  let userData = useSelector((state) => state.cms.userData);
  const [openDialog, handleDisplay] = useState(false);
  const handleClose = () => {
    handleDisplay(false);
  };

  const handleShow = () => {
    setShowElment(!showElement);
    console.log(showElement, "check");
  };
  function showSingleBlog(data) {
    console.log("helloiam", data);
    dispatch(addSingleBlog(data));
  }
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    console.log("single", singleBlog);
    dispatch(getBrandProfile());
    dispatch(getCms());
    dispatch(getAllBlogs(data));
    setMounted(true);
    dispatch(
      getBrandProfile({
        projectId: project._id,
        projectName: project.projectName,
      })
    );
  }, [dispatch, setMounted]);
  const openDialogBox = () => {
    handleDisplay(true);
  };

  async function isSideBarOpen(id) {
    if (token) {
      await updateSelectedData(id);
      // selectedData = id;
      setIsShow(true);

      console.log(isShow, selectedData, id, "show");
    }
  }

  function closeSidebar(value) {
    setIsShow(value);
    updateSelectedData("");
    // selectedData = "";
    dispatch(setSelectedData({}));
    console.log(selectedData, "show");
  }

  //-----------hide/show-------
  const { token } = useParams();

  if (token) {
    let cmsStyle = document.getElementsByClassName("hoverText");
    // console.log(cmsStyle, "cmsStyle");
    for (let i = 0; i < cmsStyle.length; i++) {
      cmsStyle[i].onClick = function () {
        return false;
      };
      cmsStyle[i].classList.add("hoverText1");
    }
  }
  //----------------hide/show-----------
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"works11"} />
      <Helmet>
        <title>Work Details</title>
        <meta
          name={"description"}
          content={"Web site created using quarkly.io"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"}
          type={"image/x-icon"}
        />
      </Helmet>

      <Section
        margin="20px 0 0px 0"
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Override
          slot="SectionContent"
          margin="0px auto 0px auto"
          flex-direction="row"
          justify-content="space-between"
        />
        <Box
          margin="0px 80px 0px 0px"
          lg-margin="0px 0px 0px 0px"
          width="70%"
          md-margin="0px 0px 0px 0px"
          lg-width="100%"
        >
          <Box
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            margin="0px 0px 20px 0px"
            width="100%"
            md-height="fit-content"
          >
            <Box width="100%" md-height="fit-content" margin="0px 0px 20px 0px">
              <Image
                src={
                  singleBlog
                    ? process.env.REACT_APP_STORAGE_URL_2 + singleBlog.image
                    : "no-data"
                }
                display="block"
                width="100%"
                object-fit="cover"
                className="hoverText"
                height="350px"
                border-radius="12px"
                lg-height="350px"
                sm-height="250px"
                sm-border-radius="8px"
              />
            </Box>
            <Box
              width="100%"
              tablat-height="fit-content"
              phone-height="fit-content"
            >
              {"  "}
              <Text
                font="--headline2"
                className="hoverText"
                md-font="--headline3"
                sm-font="--headline4"
                sm-margin="0px 0px 12px 0px"
              >
                {singleBlog ? singleBlog.title : "no-data"}
              </Text>
              <Box display="flex" margin="0px 0px 20px 0px" width="100%">
                <Text
                  font="--base1"
                  color="--light"
                  margin="0px 26px 0px 0px"
                  padding="2px 10px 2px 10px"
                  background="--dark"
                  border-radius="16px"
                  display="flex"
                  justify-content="center"
                  align-items="center"
                  className="hoverText"
                  md-font="--m16"
                  sm-font="--m12"
                  sm-margin="0px 10px 0px 0px"
                >
                  {singleBlog
                    ? new Date(singleBlog.postDate).toDateString()
                    : "no-data"}
                </Text>
                {/* <Text
                  font="--base"
                  color="--darkL2"
                  opacity="0.5"
                  margin="0px 0px 0px 0px"
                  className="hoverText"
                  md-font="--r16"
                  sm-font="--r12"
                >
                
                </Text> */}
              </Box>
              <Text
                font="--base"
                className="hoverText"
                margin="0px 0px 0px 0px"
                md-font="--r14"
                sm-font="--r12"
                dangerouslySetInnerHTML={{ __html: singleBlog.content }}
              ></Text>
            </Box>
          </Box>
        </Box>
        <Box
          width="30%"
          lg-width="50%"
          md-display="none"
          lg-display="none"
          display="flex"
          flex-direction="column"
          align-items="flex-end"
        >
          <LinkBox
            margin="0px 0px 24px 0px"
            align-items="center"
            flex-direction="column"
            justify-content="flex-start"
            sm-flex-direction="column"
            sm-align-items="flex-start"
            padding="20px 28px 20px 28px"
            background="--light2"
            border-radius="12px"
          >
            <Box
              margin="0px 0px 20px 0px"
              sm-margin="0px 0px 10px 0px"
              sm-width="100%"
              border-radius="100%"
              align-items="center"
              display="flex"
              justify-content="center"
              id="9053862707"
            >
              <Image
                src="https://images.unsplash.com/photo-1453396450673-3fe83d2db2c4?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
                border-radius="100%"
                object-fit="cover"
                width="150px"
                sm-width="100%"
                background="#ececec"
                className="hoverText"
                object-position="0% 25%"
                height="150px"
              />
            </Box>
            <Box width="100%" sm-width="100%">
              <Text
                margin="0px 0px 16px 0px"
                font="--headline3"
                color="--dark"
                md-width="100%"
                width="100%"
                className="hoverText"
                text-align="center"
                id="8275376878"
                onClick={() => isSideBarOpen("8275376878")}
              >
                {data
                  ? data["8275376878"]
                  : "Hi, I am John,<br /> Creative Technologist"}
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                color="--darkL2"
                md-width="100%"
                lg-width="80%"
                className="hoverText"
                width="100%"
                text-align="center"
                id="3859510262"
                onClick={() => isSideBarOpen("3859510262")}
              >
                {data
                  ? data["3859510262"]
                  : "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit molli"}
              </Text>
            </Box>
          </LinkBox>
          <Hr
            min-height="1px"
            min-width="100%"
            margin="0px 0px 20px 0px"
            height="1px"
            border-style="solid"
            border-color="#cacaca"
          />
          <Text
            margin="0px 0px 20px 0px"
            font="--headline2"
            color="--dark"
            md-width="100%"
            width="100%"
            className="hoverText"
            id="6065157805"
            onClick={() => isSideBarOpen("6065157805")}
          >
            {data ? data["6065157805"] : " Popular Blogs"}
          </Text>
          <div className="w-full">
            {latestBlog
              ? latestBlog.map((e) => {
                  return (
                    <Box
                      href="/blog-overview"
                      onClick={() => showSingleBlog(e)}
                      display="flex"
                      flex-direction="column"
                      md-width="100%"
                      margin="0px 0px 20px 0px"
                      padding="0px 0px 10px 0px"
                      id="6312825249"
                    >
                      <Image
                        margin="0px 0px 15px 0px"
                        background="--color-lightD2"
                        height="150px"
                        md-height="120px"
                        src={process.env.REACT_APP_STORAGE_URL_2 + e.image}
                        object-fit="cover"
                        className="hoverText"
                        id="7897743992"
                        onClick={() => isSideBarOpen("7897743992")}
                      />
                      <Text
                        margin="0px 0px 16px 0px"
                        font="--headline3"
                        color="--dark"
                        md-width="100%"
                        width="100%"
                        className="hoverText"
                        lg-font="--headline3"
                        md-font="--headline4"
                        sm-font="--base1"
                      >
                        {e.title}
                      </Text>
                      <Box
                        display="flex"
                        margin="0px 0px 20px 0px"
                        width="100%"
                      >
                        <Text
                          font="--m18"
                          color="--light"
                          margin="0px 26px 0px 0px"
                          padding="2px 10px 2px 10px"
                          background="--dark"
                          border-radius="16px"
                          display="flex"
                          justify-content="center"
                          align-items="center"
                          className="hoverText"
                          lg-font="--m16"
                          md-font="--m14"
                          sm-font="--b12"
                        >
                          {new Date(e.createdAt).toLocaleDateString()}
                        </Text>
                        <div className="flex gap-2 items-center">
                          {" "}
                          {e.categoryID.map((item, idx) => (
                            <Text
                              font="--r18"
                              color="--darkL2"
                              opacity="0.5"
                              margin="0px 0px 0px 0px"
                              className="hoverText"
                              lg-font="--r16"
                              md-font="--r14"
                              sm-font="--r12"
                              key={idx}
                            >
                              {item.categoryName}
                            </Text>
                          ))}
                        </div>
                      </Box>
                      <Text
                        margin="0px 0px 0px 0px"
                        font="--lead"
                        color="--darkL2"
                        md-width="100%"
                        className="hoverText  line-clamp3"
                        lg-font="--r16"
                        md-font="--r14"
                        sm-font="--r12"
                        dangerouslySetInnerHTML={{ __html: e.content }}
                      ></Text>
                    </Box>
                  );
                })
              : "no-data"}
          </div>
          <Button
            margin="0px 0px 20px 0px"
            padding="0 0 0 0"
            color="--secondary"
            background="--light"
          >
            Read More
          </Button>
          <Hr
            min-height="1px"
            min-width="100%"
            margin="0px 0px 20px 0px"
            height="1px"
            border-style="solid"
            border-color="#cacaca"
          />
          {/* <Text
            margin="0px 0px 20px 0px"
            font="--headline2"
            color="--dark"
            md-width="100%"
            width="100%"
            className="hoverText"
            id="5095898934"
            onClick={() => isSideBarOpen("5095898934")}
          >
            {data ? data["5095898934"] : "Works"}
          </Text>
          <Box
            margin="0px 0px 24px 0px"
            align-items="flex-start"
            flex-direction="column"
            justify-content="flex-start"
            sm-flex-direction="column"
            sm-align-items="flex-start"
          >
            <Box
              margin="0px 0px 20px 0px"
              sm-margin="0px 0px 10px 0px"
              sm-width="100%"
              width="100%"
            >
              <Image
                src="https://images.unsplash.com/photo-1523726491678-bf852e717f6a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
                border-radius="12px"
                object-fit="cover"
                width="100%"
                height="180px"
                sm-width="100%"
                className="hoverText"
              />
            </Box>
            <Box width="100%" sm-width="100%">
              <Text
                margin="0px 0px 16px 0px"
                font="--headline3"
                color="--dark"
                md-width="100%"
                width="100%"
                className="hoverText"
              >
                Designing Dashboards
              </Text>
              <Box display="flex" margin="0px 0px 20px 0px" width="100%">
                <Text
                  font="--base1"
                  color="--light"
                  margin="0px 26px 0px 0px"
                  padding="2px 10px 2px 10px"
                  background="--dark"
                  border-radius="16px"
                  display="flex"
                  justify-content="center"
                  align-items="center"
                  className="hoverText"
                >
                  2020
                </Text>
                <Text
                  font="--base"
                  color="--darkL2"
                  opacity="0.5"
                  margin="0px 0px 0px 0px"
                  className="hoverText"
                >
                  Dashboard
                </Text>
              </Box>
              <Text
                margin="0px 0px 0px 0px"
                font="--lead"
                color="--darkL2"
                md-width="100%"
                lg-width="80%"
                className="hoverText"
                width="100%"
              >
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </Text>
            </Box>
          </Box>
          <Button
            margin="0px 0px 20px 0px"
            padding="0 0 0 0"
            color="--secondary"
            background="--light"
          >
            View All
          </Button> */}
        </Box>
      </Section>

      {isShow ? (
        <ContentUpdate
          selectedElement={selectedData}
          isShow={isShow}
          closeSidebar={closeSidebar}
        />
      ) : (
        ""
      )}
      <RawHtml>
        <style place={"endOfHead"} rawKey={"64377e87d28fe100213859da"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
